var Da = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
};
function Ua(e) {
  return e;
}
function W(e, t, r, n, a, s, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return function() {
        return t(e.apply(this, arguments));
      };
    case 3:
      return function() {
        return r(t(e.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(r(t(e.apply(this, arguments))));
      };
    case 5:
      return function() {
        return a(n(r(t(e.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return s(a(n(r(t(e.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return o(s(a(n(r(t(e.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return c(o(s(a(n(r(t(e.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return l(c(o(s(a(n(r(t(e.apply(this, arguments)))))))));
      };
  }
}
function g(e, t, r, n, a, s, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return t(e);
    case 3:
      return r(t(e));
    case 4:
      return n(r(t(e)));
    case 5:
      return a(n(r(t(e))));
    case 6:
      return s(a(n(r(t(e)))));
    case 7:
      return o(s(a(n(r(t(e))))));
    case 8:
      return c(o(s(a(n(r(t(e)))))));
    case 9:
      return l(c(o(s(a(n(r(t(e))))))));
    default: {
      for (var u = arguments[0], h = 1; h < arguments.length; h++)
        u = arguments[h](u);
      return u;
    }
  }
}
var qa = function(e, t) {
  var r = typeof e == "number" ? function(n) {
    return n.length >= e;
  } : e;
  return function() {
    var n = Array.from(arguments);
    return r(arguments) ? t.apply(this, n) : function(a) {
      return t.apply(void 0, Da([a], n, !1));
    };
  };
}, Ba = function(e) {
  return {
    equals: function(t, r) {
      return t === r || e(t, r);
    }
  };
}, Ha = function(e) {
  return Ba(function(t, r) {
    for (var n in e)
      if (!e[n].equals(t[n], r[n]))
        return !1;
    return !0;
  });
};
globalThis && globalThis.__spreadArray;
var wn = function(e) {
  return e._tag === "Some";
}, xn = { _tag: "None" }, An = function(e) {
  return { _tag: "Some", value: e };
}, Ga = function(e) {
  return e._tag === "Left";
}, za = function(e) {
  return e._tag === "Right";
}, Fa = function(e) {
  return { _tag: "Left", left: e };
}, Ka = function(e) {
  return { _tag: "Right", right: e };
}, Wa = function(e) {
  return [e];
}, Qa = [], Ja = Object.prototype.hasOwnProperty, Ya = function(e) {
  return function(t) {
    return function(r) {
      return r.reduce(function(n, a) {
        return e.concat(n, a);
      }, t);
    };
  };
}, Xa = function(e) {
  return {
    concat: function() {
      return e;
    }
  };
}, es = function(e) {
  return {
    concat: function(t, r) {
      var n = {};
      for (var a in e)
        Ja.call(e, a) && (n[a] = e[a].concat(t[a], r[a]));
      return n;
    }
  };
}, Pt = function() {
  return { concat: Ua };
}, ts = function() {
  return { concat: function(e, t) {
    return t;
  } };
}, rs = Ya, ns = Xa(void 0), jr = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, as = Qa, ss = function(e, t) {
  return e < 0 || e >= t.length;
}, is = function(e) {
  return function(t) {
    return jr(jr([], t, !0), [e], !1);
  };
}, os = is, cs = Wa, F = {
  equals: function(e, t) {
    return e === t;
  }
}, us = {
  concat: function(e, t) {
    return e + t;
  }
}, ls = "", Xt = {
  concat: us.concat,
  empty: ls
};
F.equals;
var Sn = function(e) {
  return e.trim();
}, On = function(e) {
  return e.length === 0;
}, Je = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function jn(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function ds() {
  this.__data__ = [], this.size = 0;
}
var fs = ds;
function ps(e, t) {
  return e === t || e !== e && t !== t;
}
var er = ps, hs = er;
function vs(e, t) {
  for (var r = e.length; r--; )
    if (hs(e[r][0], t))
      return r;
  return -1;
}
var _t = vs, ys = _t, gs = Array.prototype, ms = gs.splice;
function _s(e) {
  var t = this.__data__, r = ys(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : ms.call(t, r, 1), --this.size, !0;
}
var bs = _s, Ts = _t;
function $s(e) {
  var t = this.__data__, r = Ts(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var ws = $s, xs = _t;
function As(e) {
  return xs(this.__data__, e) > -1;
}
var Ss = As, Os = _t;
function js(e, t) {
  var r = this.__data__, n = Os(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var Es = js, ks = fs, Cs = bs, Is = ws, Rs = Ss, Ns = Es;
function be(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
be.prototype.clear = ks;
be.prototype.delete = Cs;
be.prototype.get = Is;
be.prototype.has = Rs;
be.prototype.set = Ns;
var bt = be, Ps = bt;
function Ls() {
  this.__data__ = new Ps(), this.size = 0;
}
var Ms = Ls;
function Zs(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var Vs = Zs;
function Ds(e) {
  return this.__data__.get(e);
}
var Us = Ds;
function qs(e) {
  return this.__data__.has(e);
}
var Bs = qs, Hs = typeof Je == "object" && Je && Je.Object === Object && Je, En = Hs, Gs = En, zs = typeof self == "object" && self && self.Object === Object && self, Fs = Gs || zs || Function("return this")(), H = Fs, Ks = H, Ws = Ks.Symbol, Tt = Ws, Er = Tt, kn = Object.prototype, Qs = kn.hasOwnProperty, Js = kn.toString, Se = Er ? Er.toStringTag : void 0;
function Ys(e) {
  var t = Qs.call(e, Se), r = e[Se];
  try {
    e[Se] = void 0;
    var n = !0;
  } catch {
  }
  var a = Js.call(e);
  return n && (t ? e[Se] = r : delete e[Se]), a;
}
var Xs = Ys, ei = Object.prototype, ti = ei.toString;
function ri(e) {
  return ti.call(e);
}
var ni = ri, kr = Tt, ai = Xs, si = ni, ii = "[object Null]", oi = "[object Undefined]", Cr = kr ? kr.toStringTag : void 0;
function ci(e) {
  return e == null ? e === void 0 ? oi : ii : Cr && Cr in Object(e) ? ai(e) : si(e);
}
var $t = ci;
function ui(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var De = ui, li = $t, di = De, fi = "[object AsyncFunction]", pi = "[object Function]", hi = "[object GeneratorFunction]", vi = "[object Proxy]";
function yi(e) {
  if (!di(e))
    return !1;
  var t = li(e);
  return t == pi || t == hi || t == fi || t == vi;
}
var Cn = yi, gi = H, mi = gi["__core-js_shared__"], _i = mi, Lt = _i, Ir = function() {
  var e = /[^.]+$/.exec(Lt && Lt.keys && Lt.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function bi(e) {
  return !!Ir && Ir in e;
}
var Ti = bi, $i = Function.prototype, wi = $i.toString;
function xi(e) {
  if (e != null) {
    try {
      return wi.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var In = xi, Ai = Cn, Si = Ti, Oi = De, ji = In, Ei = /[\\^$.*+?()[\]{}|]/g, ki = /^\[object .+?Constructor\]$/, Ci = Function.prototype, Ii = Object.prototype, Ri = Ci.toString, Ni = Ii.hasOwnProperty, Pi = RegExp(
  "^" + Ri.call(Ni).replace(Ei, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Li(e) {
  if (!Oi(e) || Si(e))
    return !1;
  var t = Ai(e) ? Pi : ki;
  return t.test(ji(e));
}
var Mi = Li;
function Zi(e, t) {
  return e == null ? void 0 : e[t];
}
var Vi = Zi, Di = Mi, Ui = Vi;
function qi(e, t) {
  var r = Ui(e, t);
  return Di(r) ? r : void 0;
}
var ue = qi, Bi = ue, Hi = H, Gi = Bi(Hi, "Map"), tr = Gi, zi = ue, Fi = zi(Object, "create"), wt = Fi, Rr = wt;
function Ki() {
  this.__data__ = Rr ? Rr(null) : {}, this.size = 0;
}
var Wi = Ki;
function Qi(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var Ji = Qi, Yi = wt, Xi = "__lodash_hash_undefined__", eo = Object.prototype, to = eo.hasOwnProperty;
function ro(e) {
  var t = this.__data__;
  if (Yi) {
    var r = t[e];
    return r === Xi ? void 0 : r;
  }
  return to.call(t, e) ? t[e] : void 0;
}
var no = ro, ao = wt, so = Object.prototype, io = so.hasOwnProperty;
function oo(e) {
  var t = this.__data__;
  return ao ? t[e] !== void 0 : io.call(t, e);
}
var co = oo, uo = wt, lo = "__lodash_hash_undefined__";
function fo(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = uo && t === void 0 ? lo : t, this;
}
var po = fo, ho = Wi, vo = Ji, yo = no, go = co, mo = po;
function Te(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Te.prototype.clear = ho;
Te.prototype.delete = vo;
Te.prototype.get = yo;
Te.prototype.has = go;
Te.prototype.set = mo;
var _o = Te, Nr = _o, bo = bt, To = tr;
function $o() {
  this.size = 0, this.__data__ = {
    hash: new Nr(),
    map: new (To || bo)(),
    string: new Nr()
  };
}
var wo = $o;
function xo(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var Ao = xo, So = Ao;
function Oo(e, t) {
  var r = e.__data__;
  return So(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var xt = Oo, jo = xt;
function Eo(e) {
  var t = jo(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var ko = Eo, Co = xt;
function Io(e) {
  return Co(this, e).get(e);
}
var Ro = Io, No = xt;
function Po(e) {
  return No(this, e).has(e);
}
var Lo = Po, Mo = xt;
function Zo(e, t) {
  var r = Mo(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var Vo = Zo, Do = wo, Uo = ko, qo = Ro, Bo = Lo, Ho = Vo;
function $e(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
$e.prototype.clear = Do;
$e.prototype.delete = Uo;
$e.prototype.get = qo;
$e.prototype.has = Bo;
$e.prototype.set = Ho;
var Rn = $e, Go = bt, zo = tr, Fo = Rn, Ko = 200;
function Wo(e, t) {
  var r = this.__data__;
  if (r instanceof Go) {
    var n = r.__data__;
    if (!zo || n.length < Ko - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new Fo(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var Qo = Wo, Jo = bt, Yo = Ms, Xo = Vs, ec = Us, tc = Bs, rc = Qo;
function we(e) {
  var t = this.__data__ = new Jo(e);
  this.size = t.size;
}
we.prototype.clear = Yo;
we.prototype.delete = Xo;
we.prototype.get = ec;
we.prototype.has = tc;
we.prototype.set = rc;
var Nn = we;
function nc(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var ac = nc, sc = ue, ic = function() {
  try {
    var e = sc(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), oc = ic, Pr = oc;
function cc(e, t, r) {
  t == "__proto__" && Pr ? Pr(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var Pn = cc, uc = Pn, lc = er, dc = Object.prototype, fc = dc.hasOwnProperty;
function pc(e, t, r) {
  var n = e[t];
  (!(fc.call(e, t) && lc(n, r)) || r === void 0 && !(t in e)) && uc(e, t, r);
}
var Ln = pc, hc = Ln, vc = Pn;
function yc(e, t, r, n) {
  var a = !r;
  r || (r = {});
  for (var s = -1, o = t.length; ++s < o; ) {
    var c = t[s], l = n ? n(r[c], e[c], c, r, e) : void 0;
    l === void 0 && (l = e[c]), a ? vc(r, c, l) : hc(r, c, l);
  }
  return r;
}
var At = yc;
function gc(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var mc = gc;
function _c(e) {
  return e != null && typeof e == "object";
}
var xe = _c, bc = $t, Tc = xe, $c = "[object Arguments]";
function wc(e) {
  return Tc(e) && bc(e) == $c;
}
var xc = wc, Lr = xc, Ac = xe, Mn = Object.prototype, Sc = Mn.hasOwnProperty, Oc = Mn.propertyIsEnumerable, jc = Lr(function() {
  return arguments;
}()) ? Lr : function(e) {
  return Ac(e) && Sc.call(e, "callee") && !Oc.call(e, "callee");
}, Ec = jc, kc = Array.isArray, St = kc, rt = { exports: {} };
function Cc() {
  return !1;
}
var Ic = Cc;
rt.exports;
(function(e, t) {
  var r = H, n = Ic, a = t && !t.nodeType && t, s = a && !0 && e && !e.nodeType && e, o = s && s.exports === a, c = o ? r.Buffer : void 0, l = c ? c.isBuffer : void 0, u = l || n;
  e.exports = u;
})(rt, rt.exports);
var rr = rt.exports, Rc = 9007199254740991, Nc = /^(?:0|[1-9]\d*)$/;
function Pc(e, t) {
  var r = typeof e;
  return t = t ?? Rc, !!t && (r == "number" || r != "symbol" && Nc.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Lc = Pc, Mc = 9007199254740991;
function Zc(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= Mc;
}
var Zn = Zc, Vc = $t, Dc = Zn, Uc = xe, qc = "[object Arguments]", Bc = "[object Array]", Hc = "[object Boolean]", Gc = "[object Date]", zc = "[object Error]", Fc = "[object Function]", Kc = "[object Map]", Wc = "[object Number]", Qc = "[object Object]", Jc = "[object RegExp]", Yc = "[object Set]", Xc = "[object String]", eu = "[object WeakMap]", tu = "[object ArrayBuffer]", ru = "[object DataView]", nu = "[object Float32Array]", au = "[object Float64Array]", su = "[object Int8Array]", iu = "[object Int16Array]", ou = "[object Int32Array]", cu = "[object Uint8Array]", uu = "[object Uint8ClampedArray]", lu = "[object Uint16Array]", du = "[object Uint32Array]", A = {};
A[nu] = A[au] = A[su] = A[iu] = A[ou] = A[cu] = A[uu] = A[lu] = A[du] = !0;
A[qc] = A[Bc] = A[tu] = A[Hc] = A[ru] = A[Gc] = A[zc] = A[Fc] = A[Kc] = A[Wc] = A[Qc] = A[Jc] = A[Yc] = A[Xc] = A[eu] = !1;
function fu(e) {
  return Uc(e) && Dc(e.length) && !!A[Vc(e)];
}
var pu = fu;
function hu(e) {
  return function(t) {
    return e(t);
  };
}
var nr = hu, nt = { exports: {} };
nt.exports;
(function(e, t) {
  var r = En, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, o = s && r.process, c = function() {
    try {
      var l = a && a.require && a.require("util").types;
      return l || o && o.binding && o.binding("util");
    } catch {
    }
  }();
  e.exports = c;
})(nt, nt.exports);
var ar = nt.exports, vu = pu, yu = nr, Mr = ar, Zr = Mr && Mr.isTypedArray, gu = Zr ? yu(Zr) : vu, Vn = gu, mu = mc, _u = Ec, bu = St, Tu = rr, $u = Lc, wu = Vn, xu = Object.prototype, Au = xu.hasOwnProperty;
function Su(e, t) {
  var r = bu(e), n = !r && _u(e), a = !r && !n && Tu(e), s = !r && !n && !a && wu(e), o = r || n || a || s, c = o ? mu(e.length, String) : [], l = c.length;
  for (var u in e)
    (t || Au.call(e, u)) && !(o && // Safari 9 has enumerable `arguments.length` in strict mode.
    (u == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    a && (u == "offset" || u == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    s && (u == "buffer" || u == "byteLength" || u == "byteOffset") || // Skip index properties.
    $u(u, l))) && c.push(u);
  return c;
}
var Dn = Su, Ou = Object.prototype;
function ju(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || Ou;
  return e === r;
}
var sr = ju;
function Eu(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var Un = Eu, ku = Un, Cu = ku(Object.keys, Object), Iu = Cu, Ru = sr, Nu = Iu, Pu = Object.prototype, Lu = Pu.hasOwnProperty;
function Mu(e) {
  if (!Ru(e))
    return Nu(e);
  var t = [];
  for (var r in Object(e))
    Lu.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var Zu = Mu, Vu = Cn, Du = Zn;
function Uu(e) {
  return e != null && Du(e.length) && !Vu(e);
}
var qn = Uu, qu = Dn, Bu = Zu, Hu = qn;
function Gu(e) {
  return Hu(e) ? qu(e) : Bu(e);
}
var ir = Gu, zu = At, Fu = ir;
function Ku(e, t) {
  return e && zu(t, Fu(t), e);
}
var Wu = Ku;
function Qu(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var Ju = Qu, Yu = De, Xu = sr, el = Ju, tl = Object.prototype, rl = tl.hasOwnProperty;
function nl(e) {
  if (!Yu(e))
    return el(e);
  var t = Xu(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !rl.call(e, n)) || r.push(n);
  return r;
}
var al = nl, sl = Dn, il = al, ol = qn;
function cl(e) {
  return ol(e) ? sl(e, !0) : il(e);
}
var or = cl, ul = At, ll = or;
function dl(e, t) {
  return e && ul(t, ll(t), e);
}
var fl = dl, at = { exports: {} };
at.exports;
(function(e, t) {
  var r = H, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, o = s ? r.Buffer : void 0, c = o ? o.allocUnsafe : void 0;
  function l(u, h) {
    if (h)
      return u.slice();
    var T = u.length, w = c ? c(T) : new u.constructor(T);
    return u.copy(w), w;
  }
  e.exports = l;
})(at, at.exports);
var pl = at.exports;
function hl(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var vl = hl;
function yl(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = 0, s = []; ++r < n; ) {
    var o = e[r];
    t(o, r, e) && (s[a++] = o);
  }
  return s;
}
var gl = yl;
function ml() {
  return [];
}
var Bn = ml, _l = gl, bl = Bn, Tl = Object.prototype, $l = Tl.propertyIsEnumerable, Vr = Object.getOwnPropertySymbols, wl = Vr ? function(e) {
  return e == null ? [] : (e = Object(e), _l(Vr(e), function(t) {
    return $l.call(e, t);
  }));
} : bl, cr = wl, xl = At, Al = cr;
function Sl(e, t) {
  return xl(e, Al(e), t);
}
var Ol = Sl;
function jl(e, t) {
  for (var r = -1, n = t.length, a = e.length; ++r < n; )
    e[a + r] = t[r];
  return e;
}
var Hn = jl, El = Un, kl = El(Object.getPrototypeOf, Object), Gn = kl, Cl = Hn, Il = Gn, Rl = cr, Nl = Bn, Pl = Object.getOwnPropertySymbols, Ll = Pl ? function(e) {
  for (var t = []; e; )
    Cl(t, Rl(e)), e = Il(e);
  return t;
} : Nl, zn = Ll, Ml = At, Zl = zn;
function Vl(e, t) {
  return Ml(e, Zl(e), t);
}
var Dl = Vl, Ul = Hn, ql = St;
function Bl(e, t, r) {
  var n = t(e);
  return ql(e) ? n : Ul(n, r(e));
}
var Fn = Bl, Hl = Fn, Gl = cr, zl = ir;
function Fl(e) {
  return Hl(e, zl, Gl);
}
var Kn = Fl, Kl = Fn, Wl = zn, Ql = or;
function Jl(e) {
  return Kl(e, Ql, Wl);
}
var Yl = Jl, Xl = ue, ed = H, td = Xl(ed, "DataView"), rd = td, nd = ue, ad = H, sd = nd(ad, "Promise"), id = sd, od = ue, cd = H, ud = od(cd, "Set"), ld = ud, dd = ue, fd = H, pd = dd(fd, "WeakMap"), hd = pd, Dt = rd, Ut = tr, qt = id, Bt = ld, Ht = hd, Wn = $t, Ae = In, Dr = "[object Map]", vd = "[object Object]", Ur = "[object Promise]", qr = "[object Set]", Br = "[object WeakMap]", Hr = "[object DataView]", yd = Ae(Dt), gd = Ae(Ut), md = Ae(qt), _d = Ae(Bt), bd = Ae(Ht), ae = Wn;
(Dt && ae(new Dt(new ArrayBuffer(1))) != Hr || Ut && ae(new Ut()) != Dr || qt && ae(qt.resolve()) != Ur || Bt && ae(new Bt()) != qr || Ht && ae(new Ht()) != Br) && (ae = function(e) {
  var t = Wn(e), r = t == vd ? e.constructor : void 0, n = r ? Ae(r) : "";
  if (n)
    switch (n) {
      case yd:
        return Hr;
      case gd:
        return Dr;
      case md:
        return Ur;
      case _d:
        return qr;
      case bd:
        return Br;
    }
  return t;
});
var Ot = ae, Td = Object.prototype, $d = Td.hasOwnProperty;
function wd(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && $d.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var xd = wd, Ad = H, Sd = Ad.Uint8Array, Qn = Sd, Gr = Qn;
function Od(e) {
  var t = new e.constructor(e.byteLength);
  return new Gr(t).set(new Gr(e)), t;
}
var ur = Od, jd = ur;
function Ed(e, t) {
  var r = t ? jd(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var kd = Ed, Cd = /\w*$/;
function Id(e) {
  var t = new e.constructor(e.source, Cd.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Rd = Id, zr = Tt, Fr = zr ? zr.prototype : void 0, Kr = Fr ? Fr.valueOf : void 0;
function Nd(e) {
  return Kr ? Object(Kr.call(e)) : {};
}
var Pd = Nd, Ld = ur;
function Md(e, t) {
  var r = t ? Ld(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var Zd = Md, Vd = ur, Dd = kd, Ud = Rd, qd = Pd, Bd = Zd, Hd = "[object Boolean]", Gd = "[object Date]", zd = "[object Map]", Fd = "[object Number]", Kd = "[object RegExp]", Wd = "[object Set]", Qd = "[object String]", Jd = "[object Symbol]", Yd = "[object ArrayBuffer]", Xd = "[object DataView]", ef = "[object Float32Array]", tf = "[object Float64Array]", rf = "[object Int8Array]", nf = "[object Int16Array]", af = "[object Int32Array]", sf = "[object Uint8Array]", of = "[object Uint8ClampedArray]", cf = "[object Uint16Array]", uf = "[object Uint32Array]";
function lf(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case Yd:
      return Vd(e);
    case Hd:
    case Gd:
      return new n(+e);
    case Xd:
      return Dd(e, r);
    case ef:
    case tf:
    case rf:
    case nf:
    case af:
    case sf:
    case of:
    case cf:
    case uf:
      return Bd(e, r);
    case zd:
      return new n();
    case Fd:
    case Qd:
      return new n(e);
    case Kd:
      return Ud(e);
    case Wd:
      return new n();
    case Jd:
      return qd(e);
  }
}
var df = lf, ff = De, Wr = Object.create, pf = function() {
  function e() {
  }
  return function(t) {
    if (!ff(t))
      return {};
    if (Wr)
      return Wr(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), hf = pf, vf = hf, yf = Gn, gf = sr;
function mf(e) {
  return typeof e.constructor == "function" && !gf(e) ? vf(yf(e)) : {};
}
var _f = mf, bf = Ot, Tf = xe, $f = "[object Map]";
function wf(e) {
  return Tf(e) && bf(e) == $f;
}
var xf = wf, Af = xf, Sf = nr, Qr = ar, Jr = Qr && Qr.isMap, Of = Jr ? Sf(Jr) : Af, jf = Of, Ef = Ot, kf = xe, Cf = "[object Set]";
function If(e) {
  return kf(e) && Ef(e) == Cf;
}
var Rf = If, Nf = Rf, Pf = nr, Yr = ar, Xr = Yr && Yr.isSet, Lf = Xr ? Pf(Xr) : Nf, Mf = Lf, Zf = Nn, Vf = ac, Df = Ln, Uf = Wu, qf = fl, Bf = pl, Hf = vl, Gf = Ol, zf = Dl, Ff = Kn, Kf = Yl, Wf = Ot, Qf = xd, Jf = df, Yf = _f, Xf = St, ep = rr, tp = jf, rp = De, np = Mf, ap = ir, sp = or, ip = 1, op = 2, cp = 4, Jn = "[object Arguments]", up = "[object Array]", lp = "[object Boolean]", dp = "[object Date]", fp = "[object Error]", Yn = "[object Function]", pp = "[object GeneratorFunction]", hp = "[object Map]", vp = "[object Number]", Xn = "[object Object]", yp = "[object RegExp]", gp = "[object Set]", mp = "[object String]", _p = "[object Symbol]", bp = "[object WeakMap]", Tp = "[object ArrayBuffer]", $p = "[object DataView]", wp = "[object Float32Array]", xp = "[object Float64Array]", Ap = "[object Int8Array]", Sp = "[object Int16Array]", Op = "[object Int32Array]", jp = "[object Uint8Array]", Ep = "[object Uint8ClampedArray]", kp = "[object Uint16Array]", Cp = "[object Uint32Array]", x = {};
x[Jn] = x[up] = x[Tp] = x[$p] = x[lp] = x[dp] = x[wp] = x[xp] = x[Ap] = x[Sp] = x[Op] = x[hp] = x[vp] = x[Xn] = x[yp] = x[gp] = x[mp] = x[_p] = x[jp] = x[Ep] = x[kp] = x[Cp] = !0;
x[fp] = x[Yn] = x[bp] = !1;
function et(e, t, r, n, a, s) {
  var o, c = t & ip, l = t & op, u = t & cp;
  if (r && (o = a ? r(e, n, a, s) : r(e)), o !== void 0)
    return o;
  if (!rp(e))
    return e;
  var h = Xf(e);
  if (h) {
    if (o = Qf(e), !c)
      return Hf(e, o);
  } else {
    var T = Wf(e), w = T == Yn || T == pp;
    if (ep(e))
      return Bf(e, c);
    if (T == Xn || T == Jn || w && !a) {
      if (o = l || w ? {} : Yf(e), !c)
        return l ? zf(e, qf(o, e)) : Gf(e, Uf(o, e));
    } else {
      if (!x[T])
        return a ? e : {};
      o = Jf(e, T, c);
    }
  }
  s || (s = new Zf());
  var C = s.get(e);
  if (C)
    return C;
  s.set(e, o), np(e) ? e.forEach(function(k) {
    o.add(et(k, t, r, k, e, s));
  }) : tp(e) && e.forEach(function(k, R) {
    o.set(R, et(k, t, r, R, e, s));
  });
  var P = u ? l ? Kf : Ff : l ? sp : ap, L = h ? void 0 : P(e);
  return Vf(L || e, function(k, R) {
    L && (R = k, k = e[R]), Df(o, R, et(k, t, r, R, e, s));
  }), o;
}
var Ip = et, Rp = Ip, Np = 1, Pp = 4;
function Lp(e) {
  return Rp(e, Np | Pp);
}
var Mp = Lp;
const Zp = /* @__PURE__ */ jn(Mp);
var $;
(function(e) {
  e.assertEqual = (a) => a;
  function t(a) {
  }
  e.assertIs = t;
  function r(a) {
    throw new Error();
  }
  e.assertNever = r, e.arrayToEnum = (a) => {
    const s = {};
    for (const o of a)
      s[o] = o;
    return s;
  }, e.getValidEnumValues = (a) => {
    const s = e.objectKeys(a).filter((c) => typeof a[a[c]] != "number"), o = {};
    for (const c of s)
      o[c] = a[c];
    return e.objectValues(o);
  }, e.objectValues = (a) => e.objectKeys(a).map(function(s) {
    return a[s];
  }), e.objectKeys = typeof Object.keys == "function" ? (a) => Object.keys(a) : (a) => {
    const s = [];
    for (const o in a)
      Object.prototype.hasOwnProperty.call(a, o) && s.push(o);
    return s;
  }, e.find = (a, s) => {
    for (const o of a)
      if (s(o))
        return o;
  }, e.isInteger = typeof Number.isInteger == "function" ? (a) => Number.isInteger(a) : (a) => typeof a == "number" && isFinite(a) && Math.floor(a) === a;
  function n(a, s = " | ") {
    return a.map((o) => typeof o == "string" ? `'${o}'` : o).join(s);
  }
  e.joinValues = n, e.jsonStringifyReplacer = (a, s) => typeof s == "bigint" ? s.toString() : s;
})($ || ($ = {}));
var Gt;
(function(e) {
  e.mergeShapes = (t, r) => ({
    ...t,
    ...r
    // second overwrites first
  });
})(Gt || (Gt = {}));
const f = $.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), K = (e) => {
  switch (typeof e) {
    case "undefined":
      return f.undefined;
    case "string":
      return f.string;
    case "number":
      return isNaN(e) ? f.nan : f.number;
    case "boolean":
      return f.boolean;
    case "function":
      return f.function;
    case "bigint":
      return f.bigint;
    case "symbol":
      return f.symbol;
    case "object":
      return Array.isArray(e) ? f.array : e === null ? f.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? f.promise : typeof Map < "u" && e instanceof Map ? f.map : typeof Set < "u" && e instanceof Set ? f.set : typeof Date < "u" && e instanceof Date ? f.date : f.object;
    default:
      return f.unknown;
  }
}, d = $.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), Vp = (e) => JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
class Z extends Error {
  constructor(t) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = t;
  }
  get errors() {
    return this.issues;
  }
  format(t) {
    const r = t || function(s) {
      return s.message;
    }, n = { _errors: [] }, a = (s) => {
      for (const o of s.issues)
        if (o.code === "invalid_union")
          o.unionErrors.map(a);
        else if (o.code === "invalid_return_type")
          a(o.returnTypeError);
        else if (o.code === "invalid_arguments")
          a(o.argumentsError);
        else if (o.path.length === 0)
          n._errors.push(r(o));
        else {
          let c = n, l = 0;
          for (; l < o.path.length; ) {
            const u = o.path[l];
            l === o.path.length - 1 ? (c[u] = c[u] || { _errors: [] }, c[u]._errors.push(r(o))) : c[u] = c[u] || { _errors: [] }, c = c[u], l++;
          }
        }
    };
    return a(this), n;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, $.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(t = (r) => r.message) {
    const r = {}, n = [];
    for (const a of this.issues)
      a.path.length > 0 ? (r[a.path[0]] = r[a.path[0]] || [], r[a.path[0]].push(t(a))) : n.push(t(a));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
Z.create = (e) => new Z(e);
const Oe = (e, t) => {
  let r;
  switch (e.code) {
    case d.invalid_type:
      e.received === f.undefined ? r = "Required" : r = `Expected ${e.expected}, received ${e.received}`;
      break;
    case d.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(e.expected, $.jsonStringifyReplacer)}`;
      break;
    case d.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${$.joinValues(e.keys, ", ")}`;
      break;
    case d.invalid_union:
      r = "Invalid input";
      break;
    case d.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${$.joinValues(e.options)}`;
      break;
    case d.invalid_enum_value:
      r = `Invalid enum value. Expected ${$.joinValues(e.options)}, received '${e.received}'`;
      break;
    case d.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case d.invalid_return_type:
      r = "Invalid function return type";
      break;
    case d.invalid_date:
      r = "Invalid date";
      break;
    case d.invalid_string:
      typeof e.validation == "object" ? "includes" in e.validation ? (r = `Invalid input: must include "${e.validation.includes}"`, typeof e.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${e.validation.position}`)) : "startsWith" in e.validation ? r = `Invalid input: must start with "${e.validation.startsWith}"` : "endsWith" in e.validation ? r = `Invalid input: must end with "${e.validation.endsWith}"` : $.assertNever(e.validation) : e.validation !== "regex" ? r = `Invalid ${e.validation}` : r = "Invalid";
      break;
    case d.too_small:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "more than"} ${e.minimum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "over"} ${e.minimum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${e.minimum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(e.minimum))}` : r = "Invalid input";
      break;
    case d.too_big:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "less than"} ${e.maximum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "under"} ${e.maximum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "bigint" ? r = `BigInt must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly" : e.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(e.maximum))}` : r = "Invalid input";
      break;
    case d.custom:
      r = "Invalid input";
      break;
    case d.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case d.not_multiple_of:
      r = `Number must be a multiple of ${e.multipleOf}`;
      break;
    case d.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = t.defaultError, $.assertNever(e);
  }
  return { message: r };
};
let ea = Oe;
function Dp(e) {
  ea = e;
}
function st() {
  return ea;
}
const it = (e) => {
  const { data: t, path: r, errorMaps: n, issueData: a } = e, s = [...r, ...a.path || []], o = {
    ...a,
    path: s
  };
  let c = "";
  const l = n.filter((u) => !!u).slice().reverse();
  for (const u of l)
    c = u(o, { data: t, defaultError: c }).message;
  return {
    ...a,
    path: s,
    message: a.message || c
  };
}, Up = [];
function p(e, t) {
  const r = it({
    issueData: t,
    data: e.data,
    path: e.path,
    errorMaps: [
      e.common.contextualErrorMap,
      e.schemaErrorMap,
      st(),
      Oe
      // then global default map
    ].filter((n) => !!n)
  });
  e.common.issues.push(r);
}
class I {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(t, r) {
    const n = [];
    for (const a of r) {
      if (a.status === "aborted")
        return m;
      a.status === "dirty" && t.dirty(), n.push(a.value);
    }
    return { status: t.value, value: n };
  }
  static async mergeObjectAsync(t, r) {
    const n = [];
    for (const a of r)
      n.push({
        key: await a.key,
        value: await a.value
      });
    return I.mergeObjectSync(t, n);
  }
  static mergeObjectSync(t, r) {
    const n = {};
    for (const a of r) {
      const { key: s, value: o } = a;
      if (s.status === "aborted" || o.status === "aborted")
        return m;
      s.status === "dirty" && t.dirty(), o.status === "dirty" && t.dirty(), s.value !== "__proto__" && (typeof o.value < "u" || a.alwaysSet) && (n[s.value] = o.value);
    }
    return { status: t.value, value: n };
  }
}
const m = Object.freeze({
  status: "aborted"
}), ta = (e) => ({ status: "dirty", value: e }), N = (e) => ({ status: "valid", value: e }), zt = (e) => e.status === "aborted", Ft = (e) => e.status === "dirty", je = (e) => e.status === "valid", ot = (e) => typeof Promise < "u" && e instanceof Promise;
var v;
(function(e) {
  e.errToObj = (t) => typeof t == "string" ? { message: t } : t || {}, e.toString = (t) => typeof t == "string" ? t : t == null ? void 0 : t.message;
})(v || (v = {}));
class U {
  constructor(t, r, n, a) {
    this._cachedPath = [], this.parent = t, this.data = r, this._path = n, this._key = a;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const en = (e, t) => {
  if (je(t))
    return { success: !0, data: t.value };
  if (!e.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new Z(e.common.issues);
      return this._error = r, this._error;
    }
  };
};
function _(e) {
  if (!e)
    return {};
  const { errorMap: t, invalid_type_error: r, required_error: n, description: a } = e;
  if (t && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return t ? { errorMap: t, description: a } : { errorMap: (o, c) => o.code !== "invalid_type" ? { message: c.defaultError } : typeof c.data > "u" ? { message: n ?? c.defaultError } : { message: r ?? c.defaultError }, description: a };
}
class b {
  constructor(t) {
    this.spa = this.safeParseAsync, this._def = t, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(t) {
    return K(t.data);
  }
  _getOrReturnCtx(t, r) {
    return r || {
      common: t.parent.common,
      data: t.data,
      parsedType: K(t.data),
      schemaErrorMap: this._def.errorMap,
      path: t.path,
      parent: t.parent
    };
  }
  _processInputParams(t) {
    return {
      status: new I(),
      ctx: {
        common: t.parent.common,
        data: t.data,
        parsedType: K(t.data),
        schemaErrorMap: this._def.errorMap,
        path: t.path,
        parent: t.parent
      }
    };
  }
  _parseSync(t) {
    const r = this._parse(t);
    if (ot(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(t) {
    const r = this._parse(t);
    return Promise.resolve(r);
  }
  parse(t, r) {
    const n = this.safeParse(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(t, r) {
    var n;
    const a = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: K(t)
    }, s = this._parseSync({ data: t, path: a.path, parent: a });
    return en(a, s);
  }
  async parseAsync(t, r) {
    const n = await this.safeParseAsync(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(t, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: K(t)
    }, a = this._parse({ data: t, path: n.path, parent: n }), s = await (ot(a) ? a : Promise.resolve(a));
    return en(n, s);
  }
  refine(t, r) {
    const n = (a) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(a) : r;
    return this._refinement((a, s) => {
      const o = t(a), c = () => s.addIssue({
        code: d.custom,
        ...n(a)
      });
      return typeof Promise < "u" && o instanceof Promise ? o.then((l) => l ? !0 : (c(), !1)) : o ? !0 : (c(), !1);
    });
  }
  refinement(t, r) {
    return this._refinement((n, a) => t(n) ? !0 : (a.addIssue(typeof r == "function" ? r(n, a) : r), !1));
  }
  _refinement(t) {
    return new D({
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "refinement", refinement: t }
    });
  }
  superRefine(t) {
    return this._refinement(t);
  }
  optional() {
    return G.create(this, this._def);
  }
  nullable() {
    return ce.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return V.create(this, this._def);
  }
  promise() {
    return ve.create(this, this._def);
  }
  or(t) {
    return Ie.create([this, t], this._def);
  }
  and(t) {
    return Re.create(this, t, this._def);
  }
  transform(t) {
    return new D({
      ..._(this._def),
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "transform", transform: t }
    });
  }
  default(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Ze({
      ..._(this._def),
      innerType: this,
      defaultValue: r,
      typeName: y.ZodDefault
    });
  }
  brand() {
    return new na({
      typeName: y.ZodBranded,
      type: this,
      ..._(this._def)
    });
  }
  catch(t) {
    const r = typeof t == "function" ? t : () => t;
    return new dt({
      ..._(this._def),
      innerType: this,
      catchValue: r,
      typeName: y.ZodCatch
    });
  }
  describe(t) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: t
    });
  }
  pipe(t) {
    return Ue.create(this, t);
  }
  readonly() {
    return pt.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const qp = /^c[^\s-]{8,}$/i, Bp = /^[a-z][a-z0-9]*$/, Hp = /^[0-9A-HJKMNP-TV-Z]{26}$/, Gp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, zp = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, Fp = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let Mt;
const Kp = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/, Wp = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, Qp = (e) => e.precision ? e.offset ? new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`) : new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}Z$`) : e.precision === 0 ? e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$") : e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$");
function Jp(e, t) {
  return !!((t === "v4" || !t) && Kp.test(e) || (t === "v6" || !t) && Wp.test(e));
}
class M extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = String(t.data)), this._getType(t) !== f.string) {
      const s = this._getOrReturnCtx(t);
      return p(
        s,
        {
          code: d.invalid_type,
          expected: f.string,
          received: s.parsedType
        }
        //
      ), m;
    }
    const n = new I();
    let a;
    for (const s of this._def.checks)
      if (s.kind === "min")
        t.data.length < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "max")
        t.data.length > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "length") {
        const o = t.data.length > s.value, c = t.data.length < s.value;
        (o || c) && (a = this._getOrReturnCtx(t, a), o ? p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }) : c && p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }), n.dirty());
      } else if (s.kind === "email")
        zp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "email",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "emoji")
        Mt || (Mt = new RegExp(Fp, "u")), Mt.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "emoji",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "uuid")
        Gp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "uuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid")
        qp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid2")
        Bp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid2",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "ulid")
        Hp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ulid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "url")
        try {
          new URL(t.data);
        } catch {
          a = this._getOrReturnCtx(t, a), p(a, {
            validation: "url",
            code: d.invalid_string,
            message: s.message
          }), n.dirty();
        }
      else
        s.kind === "regex" ? (s.regex.lastIndex = 0, s.regex.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "regex",
          code: d.invalid_string,
          message: s.message
        }), n.dirty())) : s.kind === "trim" ? t.data = t.data.trim() : s.kind === "includes" ? t.data.includes(s.value, s.position) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { includes: s.value, position: s.position },
          message: s.message
        }), n.dirty()) : s.kind === "toLowerCase" ? t.data = t.data.toLowerCase() : s.kind === "toUpperCase" ? t.data = t.data.toUpperCase() : s.kind === "startsWith" ? t.data.startsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { startsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "endsWith" ? t.data.endsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { endsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "datetime" ? Qp(s).test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: "datetime",
          message: s.message
        }), n.dirty()) : s.kind === "ip" ? Jp(t.data, s.version) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ip",
          code: d.invalid_string,
          message: s.message
        }), n.dirty()) : $.assertNever(s);
    return { status: n.value, value: t.data };
  }
  _regex(t, r, n) {
    return this.refinement((a) => t.test(a), {
      validation: r,
      code: d.invalid_string,
      ...v.errToObj(n)
    });
  }
  _addCheck(t) {
    return new M({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  email(t) {
    return this._addCheck({ kind: "email", ...v.errToObj(t) });
  }
  url(t) {
    return this._addCheck({ kind: "url", ...v.errToObj(t) });
  }
  emoji(t) {
    return this._addCheck({ kind: "emoji", ...v.errToObj(t) });
  }
  uuid(t) {
    return this._addCheck({ kind: "uuid", ...v.errToObj(t) });
  }
  cuid(t) {
    return this._addCheck({ kind: "cuid", ...v.errToObj(t) });
  }
  cuid2(t) {
    return this._addCheck({ kind: "cuid2", ...v.errToObj(t) });
  }
  ulid(t) {
    return this._addCheck({ kind: "ulid", ...v.errToObj(t) });
  }
  ip(t) {
    return this._addCheck({ kind: "ip", ...v.errToObj(t) });
  }
  datetime(t) {
    var r;
    return typeof t == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      message: t
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      offset: (r = t == null ? void 0 : t.offset) !== null && r !== void 0 ? r : !1,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  regex(t, r) {
    return this._addCheck({
      kind: "regex",
      regex: t,
      ...v.errToObj(r)
    });
  }
  includes(t, r) {
    return this._addCheck({
      kind: "includes",
      value: t,
      position: r == null ? void 0 : r.position,
      ...v.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(t, r) {
    return this._addCheck({
      kind: "startsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  endsWith(t, r) {
    return this._addCheck({
      kind: "endsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t,
      ...v.errToObj(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t,
      ...v.errToObj(r)
    });
  }
  length(t, r) {
    return this._addCheck({
      kind: "length",
      value: t,
      ...v.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(t) {
    return this.min(1, v.errToObj(t));
  }
  trim() {
    return new M({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new M({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new M({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((t) => t.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((t) => t.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((t) => t.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((t) => t.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((t) => t.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((t) => t.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((t) => t.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((t) => t.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((t) => t.kind === "ip");
  }
  get minLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
M.create = (e) => {
  var t;
  return new M({
    checks: [],
    typeName: y.ZodString,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
function Yp(e, t) {
  const r = (e.toString().split(".")[1] || "").length, n = (t.toString().split(".")[1] || "").length, a = r > n ? r : n, s = parseInt(e.toFixed(a).replace(".", "")), o = parseInt(t.toFixed(a).replace(".", ""));
  return s % o / Math.pow(10, a);
}
class Q extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = Number(t.data)), this._getType(t) !== f.number) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.number,
        received: s.parsedType
      }), m;
    }
    let n;
    const a = new I();
    for (const s of this._def.checks)
      s.kind === "int" ? $.isInteger(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.invalid_type,
        expected: "integer",
        received: "float",
        message: s.message
      }), a.dirty()) : s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        minimum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        maximum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? Yp(t.data, s.value) !== 0 && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : s.kind === "finite" ? Number.isFinite(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_finite,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new Q({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new Q({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  int(t) {
    return this._addCheck({
      kind: "int",
      message: v.toString(t)
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  finite(t) {
    return this._addCheck({
      kind: "finite",
      message: v.toString(t)
    });
  }
  safe(t) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: v.toString(t)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: v.toString(t)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
  get isInt() {
    return !!this._def.checks.find((t) => t.kind === "int" || t.kind === "multipleOf" && $.isInteger(t.value));
  }
  get isFinite() {
    let t = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (t === null || n.value < t) && (t = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(t);
  }
}
Q.create = (e) => new Q({
  checks: [],
  typeName: y.ZodNumber,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class J extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = BigInt(t.data)), this._getType(t) !== f.bigint) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.bigint,
        received: s.parsedType
      }), m;
    }
    let n;
    const a = new I();
    for (const s of this._def.checks)
      s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        type: "bigint",
        minimum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        type: "bigint",
        maximum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? t.data % s.value !== BigInt(0) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new J({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new J({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
J.create = (e) => {
  var t;
  return new J({
    checks: [],
    typeName: y.ZodBigInt,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
class Ee extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = !!t.data), this._getType(t) !== f.boolean) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.boolean,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
Ee.create = (e) => new Ee({
  typeName: y.ZodBoolean,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class ie extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = new Date(t.data)), this._getType(t) !== f.date) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.date,
        received: s.parsedType
      }), m;
    }
    if (isNaN(t.data.getTime())) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_date
      }), m;
    }
    const n = new I();
    let a;
    for (const s of this._def.checks)
      s.kind === "min" ? t.data.getTime() < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_small,
        message: s.message,
        inclusive: !0,
        exact: !1,
        minimum: s.value,
        type: "date"
      }), n.dirty()) : s.kind === "max" ? t.data.getTime() > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_big,
        message: s.message,
        inclusive: !0,
        exact: !1,
        maximum: s.value,
        type: "date"
      }), n.dirty()) : $.assertNever(s);
    return {
      status: n.value,
      value: new Date(t.data.getTime())
    };
  }
  _addCheck(t) {
    return new ie({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  get minDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
  get maxDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
}
ie.create = (e) => new ie({
  checks: [],
  coerce: (e == null ? void 0 : e.coerce) || !1,
  typeName: y.ZodDate,
  ..._(e)
});
class ct extends b {
  _parse(t) {
    if (this._getType(t) !== f.symbol) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.symbol,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ct.create = (e) => new ct({
  typeName: y.ZodSymbol,
  ..._(e)
});
class ke extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.undefined,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ke.create = (e) => new ke({
  typeName: y.ZodUndefined,
  ..._(e)
});
class Ce extends b {
  _parse(t) {
    if (this._getType(t) !== f.null) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.null,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
Ce.create = (e) => new Ce({
  typeName: y.ZodNull,
  ..._(e)
});
class he extends b {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
he.create = (e) => new he({
  typeName: y.ZodAny,
  ..._(e)
});
class se extends b {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
se.create = (e) => new se({
  typeName: y.ZodUnknown,
  ..._(e)
});
class z extends b {
  _parse(t) {
    const r = this._getOrReturnCtx(t);
    return p(r, {
      code: d.invalid_type,
      expected: f.never,
      received: r.parsedType
    }), m;
  }
}
z.create = (e) => new z({
  typeName: y.ZodNever,
  ..._(e)
});
class ut extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.void,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ut.create = (e) => new ut({
  typeName: y.ZodVoid,
  ..._(e)
});
class V extends b {
  _parse(t) {
    const { ctx: r, status: n } = this._processInputParams(t), a = this._def;
    if (r.parsedType !== f.array)
      return p(r, {
        code: d.invalid_type,
        expected: f.array,
        received: r.parsedType
      }), m;
    if (a.exactLength !== null) {
      const o = r.data.length > a.exactLength.value, c = r.data.length < a.exactLength.value;
      (o || c) && (p(r, {
        code: o ? d.too_big : d.too_small,
        minimum: c ? a.exactLength.value : void 0,
        maximum: o ? a.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: a.exactLength.message
      }), n.dirty());
    }
    if (a.minLength !== null && r.data.length < a.minLength.value && (p(r, {
      code: d.too_small,
      minimum: a.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.minLength.message
    }), n.dirty()), a.maxLength !== null && r.data.length > a.maxLength.value && (p(r, {
      code: d.too_big,
      maximum: a.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((o, c) => a.type._parseAsync(new U(r, o, r.path, c)))).then((o) => I.mergeArray(n, o));
    const s = [...r.data].map((o, c) => a.type._parseSync(new U(r, o, r.path, c)));
    return I.mergeArray(n, s);
  }
  get element() {
    return this._def.type;
  }
  min(t, r) {
    return new V({
      ...this._def,
      minLength: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new V({
      ...this._def,
      maxLength: { value: t, message: v.toString(r) }
    });
  }
  length(t, r) {
    return new V({
      ...this._def,
      exactLength: { value: t, message: v.toString(r) }
    });
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
V.create = (e, t) => new V({
  type: e,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: y.ZodArray,
  ..._(t)
});
function fe(e) {
  if (e instanceof S) {
    const t = {};
    for (const r in e.shape) {
      const n = e.shape[r];
      t[r] = G.create(fe(n));
    }
    return new S({
      ...e._def,
      shape: () => t
    });
  } else
    return e instanceof V ? new V({
      ...e._def,
      type: fe(e.element)
    }) : e instanceof G ? G.create(fe(e.unwrap())) : e instanceof ce ? ce.create(fe(e.unwrap())) : e instanceof q ? q.create(e.items.map((t) => fe(t))) : e;
}
class S extends b {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const t = this._def.shape(), r = $.objectKeys(t);
    return this._cached = { shape: t, keys: r };
  }
  _parse(t) {
    if (this._getType(t) !== f.object) {
      const u = this._getOrReturnCtx(t);
      return p(u, {
        code: d.invalid_type,
        expected: f.object,
        received: u.parsedType
      }), m;
    }
    const { status: n, ctx: a } = this._processInputParams(t), { shape: s, keys: o } = this._getCached(), c = [];
    if (!(this._def.catchall instanceof z && this._def.unknownKeys === "strip"))
      for (const u in a.data)
        o.includes(u) || c.push(u);
    const l = [];
    for (const u of o) {
      const h = s[u], T = a.data[u];
      l.push({
        key: { status: "valid", value: u },
        value: h._parse(new U(a, T, a.path, u)),
        alwaysSet: u in a.data
      });
    }
    if (this._def.catchall instanceof z) {
      const u = this._def.unknownKeys;
      if (u === "passthrough")
        for (const h of c)
          l.push({
            key: { status: "valid", value: h },
            value: { status: "valid", value: a.data[h] }
          });
      else if (u === "strict")
        c.length > 0 && (p(a, {
          code: d.unrecognized_keys,
          keys: c
        }), n.dirty());
      else if (u !== "strip")
        throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const u = this._def.catchall;
      for (const h of c) {
        const T = a.data[h];
        l.push({
          key: { status: "valid", value: h },
          value: u._parse(
            new U(a, T, a.path, h)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: h in a.data
        });
      }
    }
    return a.common.async ? Promise.resolve().then(async () => {
      const u = [];
      for (const h of l) {
        const T = await h.key;
        u.push({
          key: T,
          value: await h.value,
          alwaysSet: h.alwaysSet
        });
      }
      return u;
    }).then((u) => I.mergeObjectSync(n, u)) : I.mergeObjectSync(n, l);
  }
  get shape() {
    return this._def.shape();
  }
  strict(t) {
    return v.errToObj, new S({
      ...this._def,
      unknownKeys: "strict",
      ...t !== void 0 ? {
        errorMap: (r, n) => {
          var a, s, o, c;
          const l = (o = (s = (a = this._def).errorMap) === null || s === void 0 ? void 0 : s.call(a, r, n).message) !== null && o !== void 0 ? o : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (c = v.errToObj(t).message) !== null && c !== void 0 ? c : l
          } : {
            message: l
          };
        }
      } : {}
    });
  }
  strip() {
    return new S({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new S({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(t) {
    return new S({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...t
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(t) {
    return new S({
      unknownKeys: t._def.unknownKeys,
      catchall: t._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...t._def.shape()
      }),
      typeName: y.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(t, r) {
    return this.augment({ [t]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(t) {
    return new S({
      ...this._def,
      catchall: t
    });
  }
  pick(t) {
    const r = {};
    return $.objectKeys(t).forEach((n) => {
      t[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new S({
      ...this._def,
      shape: () => r
    });
  }
  omit(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      t[n] || (r[n] = this.shape[n]);
    }), new S({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return fe(this);
  }
  partial(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      const a = this.shape[n];
      t && !t[n] ? r[n] = a : r[n] = a.optional();
    }), new S({
      ...this._def,
      shape: () => r
    });
  }
  required(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      if (t && !t[n])
        r[n] = this.shape[n];
      else {
        let s = this.shape[n];
        for (; s instanceof G; )
          s = s._def.innerType;
        r[n] = s;
      }
    }), new S({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return ra($.objectKeys(this.shape));
  }
}
S.create = (e, t) => new S({
  shape: () => e,
  unknownKeys: "strip",
  catchall: z.create(),
  typeName: y.ZodObject,
  ..._(t)
});
S.strictCreate = (e, t) => new S({
  shape: () => e,
  unknownKeys: "strict",
  catchall: z.create(),
  typeName: y.ZodObject,
  ..._(t)
});
S.lazycreate = (e, t) => new S({
  shape: e,
  unknownKeys: "strip",
  catchall: z.create(),
  typeName: y.ZodObject,
  ..._(t)
});
class Ie extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = this._def.options;
    function a(s) {
      for (const c of s)
        if (c.result.status === "valid")
          return c.result;
      for (const c of s)
        if (c.result.status === "dirty")
          return r.common.issues.push(...c.ctx.common.issues), c.result;
      const o = s.map((c) => new Z(c.ctx.common.issues));
      return p(r, {
        code: d.invalid_union,
        unionErrors: o
      }), m;
    }
    if (r.common.async)
      return Promise.all(n.map(async (s) => {
        const o = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await s._parseAsync({
            data: r.data,
            path: r.path,
            parent: o
          }),
          ctx: o
        };
      })).then(a);
    {
      let s;
      const o = [];
      for (const l of n) {
        const u = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, h = l._parseSync({
          data: r.data,
          path: r.path,
          parent: u
        });
        if (h.status === "valid")
          return h;
        h.status === "dirty" && !s && (s = { result: h, ctx: u }), u.common.issues.length && o.push(u.common.issues);
      }
      if (s)
        return r.common.issues.push(...s.ctx.common.issues), s.result;
      const c = o.map((l) => new Z(l));
      return p(r, {
        code: d.invalid_union,
        unionErrors: c
      }), m;
    }
  }
  get options() {
    return this._def.options;
  }
}
Ie.create = (e, t) => new Ie({
  options: e,
  typeName: y.ZodUnion,
  ..._(t)
});
const tt = (e) => e instanceof Pe ? tt(e.schema) : e instanceof D ? tt(e.innerType()) : e instanceof Le ? [e.value] : e instanceof Y ? e.options : e instanceof Me ? Object.keys(e.enum) : e instanceof Ze ? tt(e._def.innerType) : e instanceof ke ? [void 0] : e instanceof Ce ? [null] : null;
class jt extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.object)
      return p(r, {
        code: d.invalid_type,
        expected: f.object,
        received: r.parsedType
      }), m;
    const n = this.discriminator, a = r.data[n], s = this.optionsMap.get(a);
    return s ? r.common.async ? s._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : s._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (p(r, {
      code: d.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), m);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(t, r, n) {
    const a = /* @__PURE__ */ new Map();
    for (const s of r) {
      const o = tt(s.shape[t]);
      if (!o)
        throw new Error(`A discriminator value for key \`${t}\` could not be extracted from all schema options`);
      for (const c of o) {
        if (a.has(c))
          throw new Error(`Discriminator property ${String(t)} has duplicate value ${String(c)}`);
        a.set(c, s);
      }
    }
    return new jt({
      typeName: y.ZodDiscriminatedUnion,
      discriminator: t,
      options: r,
      optionsMap: a,
      ..._(n)
    });
  }
}
function Kt(e, t) {
  const r = K(e), n = K(t);
  if (e === t)
    return { valid: !0, data: e };
  if (r === f.object && n === f.object) {
    const a = $.objectKeys(t), s = $.objectKeys(e).filter((c) => a.indexOf(c) !== -1), o = { ...e, ...t };
    for (const c of s) {
      const l = Kt(e[c], t[c]);
      if (!l.valid)
        return { valid: !1 };
      o[c] = l.data;
    }
    return { valid: !0, data: o };
  } else if (r === f.array && n === f.array) {
    if (e.length !== t.length)
      return { valid: !1 };
    const a = [];
    for (let s = 0; s < e.length; s++) {
      const o = e[s], c = t[s], l = Kt(o, c);
      if (!l.valid)
        return { valid: !1 };
      a.push(l.data);
    }
    return { valid: !0, data: a };
  } else
    return r === f.date && n === f.date && +e == +t ? { valid: !0, data: e } : { valid: !1 };
}
class Re extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = (s, o) => {
      if (zt(s) || zt(o))
        return m;
      const c = Kt(s.value, o.value);
      return c.valid ? ((Ft(s) || Ft(o)) && r.dirty(), { status: r.value, value: c.data }) : (p(n, {
        code: d.invalid_intersection_types
      }), m);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([s, o]) => a(s, o)) : a(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
Re.create = (e, t, r) => new Re({
  left: e,
  right: t,
  typeName: y.ZodIntersection,
  ..._(r)
});
class q extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.array)
      return p(n, {
        code: d.invalid_type,
        expected: f.array,
        received: n.parsedType
      }), m;
    if (n.data.length < this._def.items.length)
      return p(n, {
        code: d.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), m;
    !this._def.rest && n.data.length > this._def.items.length && (p(n, {
      code: d.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const s = [...n.data].map((o, c) => {
      const l = this._def.items[c] || this._def.rest;
      return l ? l._parse(new U(n, o, n.path, c)) : null;
    }).filter((o) => !!o);
    return n.common.async ? Promise.all(s).then((o) => I.mergeArray(r, o)) : I.mergeArray(r, s);
  }
  get items() {
    return this._def.items;
  }
  rest(t) {
    return new q({
      ...this._def,
      rest: t
    });
  }
}
q.create = (e, t) => {
  if (!Array.isArray(e))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new q({
    items: e,
    typeName: y.ZodTuple,
    rest: null,
    ..._(t)
  });
};
class Ne extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.object)
      return p(n, {
        code: d.invalid_type,
        expected: f.object,
        received: n.parsedType
      }), m;
    const a = [], s = this._def.keyType, o = this._def.valueType;
    for (const c in n.data)
      a.push({
        key: s._parse(new U(n, c, n.path, c)),
        value: o._parse(new U(n, n.data[c], n.path, c))
      });
    return n.common.async ? I.mergeObjectAsync(r, a) : I.mergeObjectSync(r, a);
  }
  get element() {
    return this._def.valueType;
  }
  static create(t, r, n) {
    return r instanceof b ? new Ne({
      keyType: t,
      valueType: r,
      typeName: y.ZodRecord,
      ..._(n)
    }) : new Ne({
      keyType: M.create(),
      valueType: t,
      typeName: y.ZodRecord,
      ..._(r)
    });
  }
}
class lt extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.map)
      return p(n, {
        code: d.invalid_type,
        expected: f.map,
        received: n.parsedType
      }), m;
    const a = this._def.keyType, s = this._def.valueType, o = [...n.data.entries()].map(([c, l], u) => ({
      key: a._parse(new U(n, c, n.path, [u, "key"])),
      value: s._parse(new U(n, l, n.path, [u, "value"]))
    }));
    if (n.common.async) {
      const c = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const l of o) {
          const u = await l.key, h = await l.value;
          if (u.status === "aborted" || h.status === "aborted")
            return m;
          (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
        }
        return { status: r.value, value: c };
      });
    } else {
      const c = /* @__PURE__ */ new Map();
      for (const l of o) {
        const u = l.key, h = l.value;
        if (u.status === "aborted" || h.status === "aborted")
          return m;
        (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
      }
      return { status: r.value, value: c };
    }
  }
}
lt.create = (e, t, r) => new lt({
  valueType: t,
  keyType: e,
  typeName: y.ZodMap,
  ..._(r)
});
class oe extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.set)
      return p(n, {
        code: d.invalid_type,
        expected: f.set,
        received: n.parsedType
      }), m;
    const a = this._def;
    a.minSize !== null && n.data.size < a.minSize.value && (p(n, {
      code: d.too_small,
      minimum: a.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.minSize.message
    }), r.dirty()), a.maxSize !== null && n.data.size > a.maxSize.value && (p(n, {
      code: d.too_big,
      maximum: a.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.maxSize.message
    }), r.dirty());
    const s = this._def.valueType;
    function o(l) {
      const u = /* @__PURE__ */ new Set();
      for (const h of l) {
        if (h.status === "aborted")
          return m;
        h.status === "dirty" && r.dirty(), u.add(h.value);
      }
      return { status: r.value, value: u };
    }
    const c = [...n.data.values()].map((l, u) => s._parse(new U(n, l, n.path, u)));
    return n.common.async ? Promise.all(c).then((l) => o(l)) : o(c);
  }
  min(t, r) {
    return new oe({
      ...this._def,
      minSize: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new oe({
      ...this._def,
      maxSize: { value: t, message: v.toString(r) }
    });
  }
  size(t, r) {
    return this.min(t, r).max(t, r);
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
oe.create = (e, t) => new oe({
  valueType: e,
  minSize: null,
  maxSize: null,
  typeName: y.ZodSet,
  ..._(t)
});
class pe extends b {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.function)
      return p(r, {
        code: d.invalid_type,
        expected: f.function,
        received: r.parsedType
      }), m;
    function n(c, l) {
      return it({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          st(),
          Oe
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_arguments,
          argumentsError: l
        }
      });
    }
    function a(c, l) {
      return it({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          st(),
          Oe
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_return_type,
          returnTypeError: l
        }
      });
    }
    const s = { errorMap: r.common.contextualErrorMap }, o = r.data;
    if (this._def.returns instanceof ve) {
      const c = this;
      return N(async function(...l) {
        const u = new Z([]), h = await c._def.args.parseAsync(l, s).catch((C) => {
          throw u.addIssue(n(l, C)), u;
        }), T = await Reflect.apply(o, this, h);
        return await c._def.returns._def.type.parseAsync(T, s).catch((C) => {
          throw u.addIssue(a(T, C)), u;
        });
      });
    } else {
      const c = this;
      return N(function(...l) {
        const u = c._def.args.safeParse(l, s);
        if (!u.success)
          throw new Z([n(l, u.error)]);
        const h = Reflect.apply(o, this, u.data), T = c._def.returns.safeParse(h, s);
        if (!T.success)
          throw new Z([a(h, T.error)]);
        return T.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...t) {
    return new pe({
      ...this._def,
      args: q.create(t).rest(se.create())
    });
  }
  returns(t) {
    return new pe({
      ...this._def,
      returns: t
    });
  }
  implement(t) {
    return this.parse(t);
  }
  strictImplement(t) {
    return this.parse(t);
  }
  static create(t, r, n) {
    return new pe({
      args: t || q.create([]).rest(se.create()),
      returns: r || se.create(),
      typeName: y.ZodFunction,
      ..._(n)
    });
  }
}
class Pe extends b {
  get schema() {
    return this._def.getter();
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
Pe.create = (e, t) => new Pe({
  getter: e,
  typeName: y.ZodLazy,
  ..._(t)
});
class Le extends b {
  _parse(t) {
    if (t.data !== this._def.value) {
      const r = this._getOrReturnCtx(t);
      return p(r, {
        received: r.data,
        code: d.invalid_literal,
        expected: this._def.value
      }), m;
    }
    return { status: "valid", value: t.data };
  }
  get value() {
    return this._def.value;
  }
}
Le.create = (e, t) => new Le({
  value: e,
  typeName: y.ZodLiteral,
  ..._(t)
});
function ra(e, t) {
  return new Y({
    values: e,
    typeName: y.ZodEnum,
    ..._(t)
  });
}
class Y extends b {
  _parse(t) {
    if (typeof t.data != "string") {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        expected: $.joinValues(n),
        received: r.parsedType,
        code: d.invalid_type
      }), m;
    }
    if (this._def.values.indexOf(t.data) === -1) {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        received: r.data,
        code: d.invalid_enum_value,
        options: n
      }), m;
    }
    return N(t.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Values() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  extract(t) {
    return Y.create(t);
  }
  exclude(t) {
    return Y.create(this.options.filter((r) => !t.includes(r)));
  }
}
Y.create = ra;
class Me extends b {
  _parse(t) {
    const r = $.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(t);
    if (n.parsedType !== f.string && n.parsedType !== f.number) {
      const a = $.objectValues(r);
      return p(n, {
        expected: $.joinValues(a),
        received: n.parsedType,
        code: d.invalid_type
      }), m;
    }
    if (r.indexOf(t.data) === -1) {
      const a = $.objectValues(r);
      return p(n, {
        received: n.data,
        code: d.invalid_enum_value,
        options: a
      }), m;
    }
    return N(t.data);
  }
  get enum() {
    return this._def.values;
  }
}
Me.create = (e, t) => new Me({
  values: e,
  typeName: y.ZodNativeEnum,
  ..._(t)
});
class ve extends b {
  unwrap() {
    return this._def.type;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.promise && r.common.async === !1)
      return p(r, {
        code: d.invalid_type,
        expected: f.promise,
        received: r.parsedType
      }), m;
    const n = r.parsedType === f.promise ? r.data : Promise.resolve(r.data);
    return N(n.then((a) => this._def.type.parseAsync(a, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
ve.create = (e, t) => new ve({
  type: e,
  typeName: y.ZodPromise,
  ..._(t)
});
class D extends b {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === y.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = this._def.effect || null, s = {
      addIssue: (o) => {
        p(n, o), o.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (s.addIssue = s.addIssue.bind(s), a.type === "preprocess") {
      const o = a.transform(n.data, s);
      return n.common.issues.length ? {
        status: "dirty",
        value: n.data
      } : n.common.async ? Promise.resolve(o).then((c) => this._def.schema._parseAsync({
        data: c,
        path: n.path,
        parent: n
      })) : this._def.schema._parseSync({
        data: o,
        path: n.path,
        parent: n
      });
    }
    if (a.type === "refinement") {
      const o = (c) => {
        const l = a.refinement(c, s);
        if (n.common.async)
          return Promise.resolve(l);
        if (l instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return c;
      };
      if (n.common.async === !1) {
        const c = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? m : (c.status === "dirty" && r.dirty(), o(c.value), { status: r.value, value: c.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((c) => c.status === "aborted" ? m : (c.status === "dirty" && r.dirty(), o(c.value).then(() => ({ status: r.value, value: c.value }))));
    }
    if (a.type === "transform")
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!je(o))
          return o;
        const c = a.transform(o.value, s);
        if (c instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: c };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => je(o) ? Promise.resolve(a.transform(o.value, s)).then((c) => ({ status: r.value, value: c })) : o);
    $.assertNever(a);
  }
}
D.create = (e, t, r) => new D({
  schema: e,
  typeName: y.ZodEffects,
  effect: t,
  ..._(r)
});
D.createWithPreprocess = (e, t, r) => new D({
  schema: t,
  effect: { type: "preprocess", transform: e },
  typeName: y.ZodEffects,
  ..._(r)
});
class G extends b {
  _parse(t) {
    return this._getType(t) === f.undefined ? N(void 0) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
G.create = (e, t) => new G({
  innerType: e,
  typeName: y.ZodOptional,
  ..._(t)
});
class ce extends b {
  _parse(t) {
    return this._getType(t) === f.null ? N(null) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
ce.create = (e, t) => new ce({
  innerType: e,
  typeName: y.ZodNullable,
  ..._(t)
});
class Ze extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    let n = r.data;
    return r.parsedType === f.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
Ze.create = (e, t) => new Ze({
  innerType: e,
  typeName: y.ZodDefault,
  defaultValue: typeof t.default == "function" ? t.default : () => t.default,
  ..._(t)
});
class dt extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, a = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return ot(a) ? a.then((s) => ({
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new Z(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: a.status === "valid" ? a.value : this._def.catchValue({
        get error() {
          return new Z(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
dt.create = (e, t) => new dt({
  innerType: e,
  typeName: y.ZodCatch,
  catchValue: typeof t.catch == "function" ? t.catch : () => t.catch,
  ..._(t)
});
class ft extends b {
  _parse(t) {
    if (this._getType(t) !== f.nan) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.nan,
        received: n.parsedType
      }), m;
    }
    return { status: "valid", value: t.data };
  }
}
ft.create = (e) => new ft({
  typeName: y.ZodNaN,
  ..._(e)
});
const Xp = Symbol("zod_brand");
class na extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class Ue extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.common.async)
      return (async () => {
        const s = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return s.status === "aborted" ? m : s.status === "dirty" ? (r.dirty(), ta(s.value)) : this._def.out._parseAsync({
          data: s.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const a = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return a.status === "aborted" ? m : a.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: a.value
      }) : this._def.out._parseSync({
        data: a.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(t, r) {
    return new Ue({
      in: t,
      out: r,
      typeName: y.ZodPipeline
    });
  }
}
class pt extends b {
  _parse(t) {
    const r = this._def.innerType._parse(t);
    return je(r) && (r.value = Object.freeze(r.value)), r;
  }
}
pt.create = (e, t) => new pt({
  innerType: e,
  typeName: y.ZodReadonly,
  ..._(t)
});
const aa = (e, t = {}, r) => e ? he.create().superRefine((n, a) => {
  var s, o;
  if (!e(n)) {
    const c = typeof t == "function" ? t(n) : typeof t == "string" ? { message: t } : t, l = (o = (s = c.fatal) !== null && s !== void 0 ? s : r) !== null && o !== void 0 ? o : !0, u = typeof c == "string" ? { message: c } : c;
    a.addIssue({ code: "custom", ...u, fatal: l });
  }
}) : he.create(), eh = {
  object: S.lazycreate
};
var y;
(function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodNaN = "ZodNaN", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodSymbol = "ZodSymbol", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodCatch = "ZodCatch", e.ZodPromise = "ZodPromise", e.ZodBranded = "ZodBranded", e.ZodPipeline = "ZodPipeline", e.ZodReadonly = "ZodReadonly";
})(y || (y = {}));
const th = (e, t = {
  message: `Input not instance of ${e.name}`
}) => aa((r) => r instanceof e, t), sa = M.create, ia = Q.create, rh = ft.create, nh = J.create, oa = Ee.create, ah = ie.create, sh = ct.create, ih = ke.create, oh = Ce.create, ch = he.create, uh = se.create, lh = z.create, dh = ut.create, fh = V.create, ph = S.create, hh = S.strictCreate, vh = Ie.create, yh = jt.create, gh = Re.create, mh = q.create, _h = Ne.create, bh = lt.create, Th = oe.create, $h = pe.create, wh = Pe.create, xh = Le.create, Ah = Y.create, Sh = Me.create, Oh = ve.create, tn = D.create, jh = G.create, Eh = ce.create, kh = D.createWithPreprocess, Ch = Ue.create, Ih = () => sa().optional(), Rh = () => ia().optional(), Nh = () => oa().optional(), Ph = {
  string: (e) => M.create({ ...e, coerce: !0 }),
  number: (e) => Q.create({ ...e, coerce: !0 }),
  boolean: (e) => Ee.create({
    ...e,
    coerce: !0
  }),
  bigint: (e) => J.create({ ...e, coerce: !0 }),
  date: (e) => ie.create({ ...e, coerce: !0 })
}, Lh = m;
var i = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: Oe,
  setErrorMap: Dp,
  getErrorMap: st,
  makeIssue: it,
  EMPTY_PATH: Up,
  addIssueToContext: p,
  ParseStatus: I,
  INVALID: m,
  DIRTY: ta,
  OK: N,
  isAborted: zt,
  isDirty: Ft,
  isValid: je,
  isAsync: ot,
  get util() {
    return $;
  },
  get objectUtil() {
    return Gt;
  },
  ZodParsedType: f,
  getParsedType: K,
  ZodType: b,
  ZodString: M,
  ZodNumber: Q,
  ZodBigInt: J,
  ZodBoolean: Ee,
  ZodDate: ie,
  ZodSymbol: ct,
  ZodUndefined: ke,
  ZodNull: Ce,
  ZodAny: he,
  ZodUnknown: se,
  ZodNever: z,
  ZodVoid: ut,
  ZodArray: V,
  ZodObject: S,
  ZodUnion: Ie,
  ZodDiscriminatedUnion: jt,
  ZodIntersection: Re,
  ZodTuple: q,
  ZodRecord: Ne,
  ZodMap: lt,
  ZodSet: oe,
  ZodFunction: pe,
  ZodLazy: Pe,
  ZodLiteral: Le,
  ZodEnum: Y,
  ZodNativeEnum: Me,
  ZodPromise: ve,
  ZodEffects: D,
  ZodTransformer: D,
  ZodOptional: G,
  ZodNullable: ce,
  ZodDefault: Ze,
  ZodCatch: dt,
  ZodNaN: ft,
  BRAND: Xp,
  ZodBranded: na,
  ZodPipeline: Ue,
  ZodReadonly: pt,
  custom: aa,
  Schema: b,
  ZodSchema: b,
  late: eh,
  get ZodFirstPartyTypeKind() {
    return y;
  },
  coerce: Ph,
  any: ch,
  array: fh,
  bigint: nh,
  boolean: oa,
  date: ah,
  discriminatedUnion: yh,
  effect: tn,
  enum: Ah,
  function: $h,
  instanceof: th,
  intersection: gh,
  lazy: wh,
  literal: xh,
  map: bh,
  nan: rh,
  nativeEnum: Sh,
  never: lh,
  null: oh,
  nullable: Eh,
  number: ia,
  object: ph,
  oboolean: Nh,
  onumber: Rh,
  optional: jh,
  ostring: Ih,
  pipeline: Ch,
  preprocess: kh,
  promise: Oh,
  record: _h,
  set: Th,
  strictObject: hh,
  string: sa,
  symbol: sh,
  transformer: tn,
  tuple: mh,
  undefined: ih,
  union: vh,
  unknown: uh,
  void: dh,
  NEVER: Lh,
  ZodIssueCode: d,
  quotelessJson: Vp,
  ZodError: Z
}), ee = (e) => e, Mh = class {
  /**
   * @package
   */
  constructor(e, t, r) {
    this.versionMap = e, this.latestVersion = t, this.getVersion = r;
  }
  /**
   * Returns whether the given data is a valid entity of any version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of any version of the entity.
   */
  is(e) {
    let t = this.getVersion(e);
    if (t === null)
      return !1;
    const r = this.versionMap[t];
    return r ? r.schema.safeParse(e).success : !1;
  }
  /**
   * Returns whether the given data is a valid entity of the latest version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of the latest version of the entity.
   */
  isLatest(e) {
    return this.versionMap[this.latestVersion].schema.safeParse(e).success;
  }
  /**
   * Similar to Zod's `safeParse` method, but also migrates the data to the latest version.
   * @param data The data to parse
   * @returns The result from parsing data, if successful, older versions are migrated to the latest version
   */
  safeParse(e) {
    const t = this.getVersion(e);
    if (t === null)
      return { type: "err", error: { type: "VER_CHECK_FAIL" } };
    const r = this.versionMap[t];
    if (!r)
      return { type: "err", error: { type: "INVALID_VER" } };
    const n = r.schema.safeParse(e);
    if (!n.success)
      return {
        type: "err",
        error: {
          type: "GIVEN_VER_VALIDATION_FAIL",
          version: t,
          versionDef: r,
          error: n.error
        }
      };
    let a = e;
    for (let s = t + 1; s <= this.latestVersion; s++) {
      const o = this.versionMap[s];
      if (!o)
        return {
          type: "err",
          error: { type: "BUG_NO_INTERMEDIATE_FOUND", missingVer: s }
        };
      if (o.initial)
        return {
          type: "err",
          error: { type: "BUG_INTERMEDIATE_MARKED_INITIAL", ver: s }
        };
      a = o.up(a);
    }
    return { type: "ok", value: a };
  }
};
function Et(e) {
  return new Mh(e.versionMap, e.latestVersion, e.getVersion);
}
function ht(e) {
  return i.custom((t) => e.is(t)).transform((t) => {
    const r = e.safeParse(t);
    if (r.type !== "ok")
      throw new Error(
        "Invalid entity definition. `entity.is` returned success, safeParse failed."
      );
    return r.value;
  });
}
const Zh = i.object({
  id: i.optional(i.string()),
  // Firebase Firestore ID
  url: i.string(),
  path: i.string(),
  headers: i.array(
    i.object({
      key: i.string(),
      value: i.string(),
      active: i.boolean()
    })
  ),
  params: i.array(
    i.object({
      key: i.string(),
      value: i.string(),
      active: i.boolean()
    })
  ),
  name: i.string(),
  method: i.string(),
  preRequestScript: i.string(),
  testScript: i.string(),
  contentType: i.string(),
  body: i.string(),
  rawParams: i.optional(i.string()),
  auth: i.optional(i.string()),
  httpUser: i.optional(i.string()),
  httpPassword: i.optional(i.string()),
  bearerToken: i.optional(i.string())
}), rn = ee({
  initial: !0,
  schema: Zh
}), ca = i.object({
  key: i.string(),
  active: i.boolean()
}).and(
  i.union([
    i.object({
      isFile: i.literal(!0),
      value: i.array(i.instanceof(Blob).nullable())
    }),
    i.object({
      isFile: i.literal(!1),
      value: i.string()
    })
  ])
), Hg = i.object({
  contentType: i.literal("multipart/form-data"),
  body: i.array(ca)
}), ua = i.union([
  i.object({
    contentType: i.literal(null),
    body: i.literal(null).catch(null)
  }),
  i.object({
    contentType: i.literal("multipart/form-data"),
    body: i.array(ca).catch([])
  }),
  i.object({
    contentType: i.union([
      i.literal("application/json"),
      i.literal("application/ld+json"),
      i.literal("application/hal+json"),
      i.literal("application/vnd.api+json"),
      i.literal("application/xml"),
      i.literal("application/x-www-form-urlencoded"),
      i.literal("text/html"),
      i.literal("text/plain")
    ]),
    body: i.string().catch("")
  })
]), Vh = i.object({
  authType: i.literal("none")
}), Dh = i.object({
  authType: i.literal("basic"),
  username: i.string().catch(""),
  password: i.string().catch("")
}), Uh = i.object({
  authType: i.literal("bearer"),
  token: i.string().catch("")
}), qh = i.object({
  authType: i.literal("oauth-2"),
  token: i.string().catch(""),
  oidcDiscoveryURL: i.string().catch(""),
  authURL: i.string().catch(""),
  accessTokenURL: i.string().catch(""),
  clientID: i.string().catch(""),
  scope: i.string().catch("")
}), Bh = i.object({
  authType: i.literal("api-key"),
  key: i.string().catch(""),
  value: i.string().catch(""),
  addTo: i.string().catch("Headers")
}), Hh = i.object({
  authType: i.literal("inherit")
}), lr = i.discriminatedUnion("authType", [
  Vh,
  Hh,
  Dh,
  Uh,
  qh,
  Bh
]).and(
  i.object({
    authActive: i.boolean()
  })
), la = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), dr = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), Gh = i.object({
  v: i.literal("1"),
  id: i.optional(i.string()),
  // Firebase Firestore ID
  name: i.string(),
  method: i.string(),
  endpoint: i.string(),
  params: la,
  headers: dr,
  preRequestScript: i.string().catch(""),
  testScript: i.string().catch(""),
  auth: lr,
  body: ua
});
function zh(e) {
  return {
    contentType: "application/json",
    body: e.contentType === "application/json" ? e.rawParams ?? "" : ""
  };
}
function Fh(e) {
  return !e.auth || e.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : e.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: e.httpUser ?? "",
    password: e.httpPassword ?? ""
  } : e.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: e.bearerToken ?? ""
  } : { authType: "none", authActive: !0 };
}
const Kh = ee({
  initial: !1,
  schema: Gh,
  up(e) {
    const {
      url: t,
      path: r,
      headers: n,
      params: a,
      name: s,
      method: o,
      preRequestScript: c,
      testScript: l
    } = e, u = `${t}${r}`, h = zh(e), T = Fh(e), w = {
      v: "1",
      endpoint: u,
      headers: n,
      params: a,
      name: s,
      method: o,
      preRequestScript: c,
      testScript: l,
      body: h,
      auth: T
    };
    return e.id && (w.id = e.id), w;
  }
});
var Wh = "__lodash_hash_undefined__";
function Qh(e) {
  return this.__data__.set(e, Wh), this;
}
var Jh = Qh;
function Yh(e) {
  return this.__data__.has(e);
}
var Xh = Yh, ev = Rn, tv = Jh, rv = Xh;
function vt(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new ev(); ++t < r; )
    this.add(e[t]);
}
vt.prototype.add = vt.prototype.push = tv;
vt.prototype.has = rv;
var nv = vt;
function av(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var sv = av;
function iv(e, t) {
  return e.has(t);
}
var ov = iv, cv = nv, uv = sv, lv = ov, dv = 1, fv = 2;
function pv(e, t, r, n, a, s) {
  var o = r & dv, c = e.length, l = t.length;
  if (c != l && !(o && l > c))
    return !1;
  var u = s.get(e), h = s.get(t);
  if (u && h)
    return u == t && h == e;
  var T = -1, w = !0, C = r & fv ? new cv() : void 0;
  for (s.set(e, t), s.set(t, e); ++T < c; ) {
    var P = e[T], L = t[T];
    if (n)
      var k = o ? n(L, P, T, t, e, s) : n(P, L, T, e, t, s);
    if (k !== void 0) {
      if (k)
        continue;
      w = !1;
      break;
    }
    if (C) {
      if (!uv(t, function(R, ne) {
        if (!lv(C, ne) && (P === R || a(P, R, r, n, s)))
          return C.push(ne);
      })) {
        w = !1;
        break;
      }
    } else if (!(P === L || a(P, L, r, n, s))) {
      w = !1;
      break;
    }
  }
  return s.delete(e), s.delete(t), w;
}
var da = pv;
function hv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, a) {
    r[++t] = [a, n];
  }), r;
}
var vv = hv;
function yv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var gv = yv, nn = Tt, an = Qn, mv = er, _v = da, bv = vv, Tv = gv, $v = 1, wv = 2, xv = "[object Boolean]", Av = "[object Date]", Sv = "[object Error]", Ov = "[object Map]", jv = "[object Number]", Ev = "[object RegExp]", kv = "[object Set]", Cv = "[object String]", Iv = "[object Symbol]", Rv = "[object ArrayBuffer]", Nv = "[object DataView]", sn = nn ? nn.prototype : void 0, Zt = sn ? sn.valueOf : void 0;
function Pv(e, t, r, n, a, s, o) {
  switch (r) {
    case Nv:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case Rv:
      return !(e.byteLength != t.byteLength || !s(new an(e), new an(t)));
    case xv:
    case Av:
    case jv:
      return mv(+e, +t);
    case Sv:
      return e.name == t.name && e.message == t.message;
    case Ev:
    case Cv:
      return e == t + "";
    case Ov:
      var c = bv;
    case kv:
      var l = n & $v;
      if (c || (c = Tv), e.size != t.size && !l)
        return !1;
      var u = o.get(e);
      if (u)
        return u == t;
      n |= wv, o.set(e, t);
      var h = _v(c(e), c(t), n, a, s, o);
      return o.delete(e), h;
    case Iv:
      if (Zt)
        return Zt.call(e) == Zt.call(t);
  }
  return !1;
}
var Lv = Pv, on = Kn, Mv = 1, Zv = Object.prototype, Vv = Zv.hasOwnProperty;
function Dv(e, t, r, n, a, s) {
  var o = r & Mv, c = on(e), l = c.length, u = on(t), h = u.length;
  if (l != h && !o)
    return !1;
  for (var T = l; T--; ) {
    var w = c[T];
    if (!(o ? w in t : Vv.call(t, w)))
      return !1;
  }
  var C = s.get(e), P = s.get(t);
  if (C && P)
    return C == t && P == e;
  var L = !0;
  s.set(e, t), s.set(t, e);
  for (var k = o; ++T < l; ) {
    w = c[T];
    var R = e[w], ne = t[w];
    if (n)
      var Or = o ? n(ne, R, w, t, e, s) : n(R, ne, w, e, t, s);
    if (!(Or === void 0 ? R === ne || a(R, ne, r, n, s) : Or)) {
      L = !1;
      break;
    }
    k || (k = w == "constructor");
  }
  if (L && !k) {
    var We = e.constructor, Qe = t.constructor;
    We != Qe && "constructor" in e && "constructor" in t && !(typeof We == "function" && We instanceof We && typeof Qe == "function" && Qe instanceof Qe) && (L = !1);
  }
  return s.delete(e), s.delete(t), L;
}
var Uv = Dv, Vt = Nn, qv = da, Bv = Lv, Hv = Uv, cn = Ot, un = St, ln = rr, Gv = Vn, zv = 1, dn = "[object Arguments]", fn = "[object Array]", Ye = "[object Object]", Fv = Object.prototype, pn = Fv.hasOwnProperty;
function Kv(e, t, r, n, a, s) {
  var o = un(e), c = un(t), l = o ? fn : cn(e), u = c ? fn : cn(t);
  l = l == dn ? Ye : l, u = u == dn ? Ye : u;
  var h = l == Ye, T = u == Ye, w = l == u;
  if (w && ln(e)) {
    if (!ln(t))
      return !1;
    o = !0, h = !1;
  }
  if (w && !h)
    return s || (s = new Vt()), o || Gv(e) ? qv(e, t, r, n, a, s) : Bv(e, t, l, r, n, a, s);
  if (!(r & zv)) {
    var C = h && pn.call(e, "__wrapped__"), P = T && pn.call(t, "__wrapped__");
    if (C || P) {
      var L = C ? e.value() : e, k = P ? t.value() : t;
      return s || (s = new Vt()), a(L, k, r, n, s);
    }
  }
  return w ? (s || (s = new Vt()), Hv(e, t, r, n, a, s)) : !1;
}
var Wv = Kv, Qv = Wv, hn = xe;
function fa(e, t, r, n, a) {
  return e === t ? !0 : e == null || t == null || !hn(e) && !hn(t) ? e !== e && t !== t : Qv(e, t, r, n, fa, a);
}
var Jv = fa, Yv = Jv;
function Xv(e, t) {
  return Yv(e, t);
}
var ey = Xv;
const ty = /* @__PURE__ */ jn(ey), ry = (e) => ({
  equals(t, r) {
    return t !== void 0 && r !== void 0 ? e.equals(t, r) : t === void 0 && r === void 0;
  }
}), vn = (e, t) => ({
  equals(r, n) {
    return t.equals(e(r), e(n));
  }
}), Xe = {
  equals(e, t) {
    return ty(e, t);
  }
}, ny = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "text/html": "html",
  "text/plain": "plain"
}, Gg = Object.keys(ny), ay = i.object({
  // v is a stringified number
  v: i.string().regex(/^\d+$/).transform(Number)
}), kt = Et({
  latestVersion: 1,
  versionMap: {
    0: rn,
    1: Kh
  },
  getVersion(e) {
    const t = ay.safeParse(e);
    return t.success ? t.data.v : rn.schema.safeParse(e).success ? 0 : null;
  }
}), sy = Ha({
  id: ry(F),
  v: F,
  auth: Xe,
  body: Xe,
  endpoint: F,
  headers: vn(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Xe
  ),
  params: vn(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Xe
  ),
  method: F,
  name: F,
  preRequestScript: F,
  testScript: F
}), iy = "1", zg = sy.equals;
function Fg(e, t) {
  const r = Zp(t);
  if (e && typeof e == "object") {
    if ("id" in e && typeof e.id == "string" && (r.id = e.id), "name" in e && typeof e.name == "string" && (r.name = e.name), "method" in e && typeof e.method == "string" && (r.method = e.method), "endpoint" in e && typeof e.endpoint == "string" && (r.endpoint = e.endpoint), "preRequestScript" in e && typeof e.preRequestScript == "string" && (r.preRequestScript = e.preRequestScript), "testScript" in e && typeof e.testScript == "string" && (r.testScript = e.testScript), "body" in e) {
      const n = ua.safeParse(e.body);
      n.success && (r.body = n.data);
    }
    if ("auth" in e) {
      const n = lr.safeParse(e.auth);
      n.success && (r.auth = n.data);
    }
    if ("params" in e) {
      const n = la.safeParse(e.params);
      n.success && (r.params = n.data);
    }
    if ("headers" in e) {
      const n = dr.safeParse(e.headers);
      n.success && (r.headers = n.data);
    }
  }
  return r;
}
function Kg(e) {
  return {
    v: iy,
    ...e
  };
}
function oy() {
  return {
    v: "1",
    endpoint: "https://echo.hoppscotch.io",
    name: "Untitled",
    params: [],
    headers: [],
    method: "GET",
    auth: {
      authType: "none",
      authActive: !0
    },
    preRequestScript: "",
    testScript: "",
    body: {
      contentType: null,
      body: null
    }
  };
}
function Wg(e) {
  return kt.isLatest(e);
}
function cy(e) {
  const t = kt.safeParse(e);
  return t.type === "ok" ? t.value : oy();
}
const fr = i.object({
  key: i.string().catch(""),
  value: i.string().catch(""),
  active: i.boolean().catch(!0)
}), uy = i.object({
  v: i.literal(1),
  name: i.string(),
  url: i.string(),
  headers: i.array(fr).catch([]),
  query: i.string(),
  variables: i.string()
}), ly = ee({
  initial: !0,
  schema: uy
}), dy = i.object({
  authType: i.literal("none")
}), fy = i.object({
  authType: i.literal("basic"),
  username: i.string().catch(""),
  password: i.string().catch("")
}), py = i.object({
  authType: i.literal("bearer"),
  token: i.string().catch("")
}), hy = i.object({
  authType: i.literal("oauth-2"),
  token: i.string().catch(""),
  oidcDiscoveryURL: i.string().catch(""),
  authURL: i.string().catch(""),
  accessTokenURL: i.string().catch(""),
  clientID: i.string().catch(""),
  scope: i.string().catch("")
}), vy = i.object({
  authType: i.literal("api-key"),
  key: i.string().catch(""),
  value: i.string().catch(""),
  addTo: i.string().catch("Headers")
}), yy = i.object({
  authType: i.literal("inherit")
}), pa = i.discriminatedUnion("authType", [
  dy,
  fy,
  py,
  hy,
  vy,
  yy
]).and(
  i.object({
    authActive: i.boolean()
  })
), gy = i.object({
  id: i.optional(i.string()),
  v: i.literal(2),
  name: i.string(),
  url: i.string(),
  headers: i.array(fr).catch([]),
  query: i.string(),
  variables: i.string(),
  auth: pa
}), my = ee({
  initial: !1,
  schema: gy,
  up(e) {
    return {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "none"
      }
    };
  }
}), ha = 2, _y = i.object({
  v: i.number()
}), pr = Et({
  latestVersion: 2,
  versionMap: {
    1: ly,
    2: my
  },
  getVersion(e) {
    const t = _y.safeParse(e);
    return t.success ? t.data.v : null;
  }
}), by = `
query Request {
  method
  url
  headers {
    key
    value
  }
}`.trim();
function Ty() {
  return {
    v: ha,
    name: "Untitled",
    url: "https://echo.hoppscotch.io/graphql",
    headers: [],
    variables: `
{
  "id": "1"
}`.trim(),
    query: by,
    auth: {
      authType: "none",
      authActive: !0
    }
  };
}
function $y(e) {
  const t = pr.safeParse(e);
  return t.type === "ok" ? t.value : Ty();
}
function Qg(e) {
  return {
    v: ha,
    ...e
  };
}
const wy = i.object({
  v: i.literal(1),
  id: i.optional(i.string()),
  // For Firestore ID data
  name: i.string(),
  requests: i.array(
    i.lazy(
      () => i.union([
        ht(kt),
        ht(pr)
      ])
    )
  )
}), va = wy.extend({
  folders: i.lazy(() => i.array(va))
}), yn = ee({
  initial: !0,
  schema: va
}), xy = i.object({
  v: i.literal(2),
  id: i.optional(i.string()),
  // For Firestore ID data
  name: i.string(),
  requests: i.array(
    i.lazy(
      () => i.union([
        ht(kt),
        ht(pr)
      ])
    )
  ),
  auth: i.union([lr, pa]),
  headers: i.union([dr, i.array(fr)])
}), ya = xy.extend({
  folders: i.lazy(() => i.array(ya))
}), Ay = ee({
  initial: !1,
  schema: ya,
  up(e) {
    const t = {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "inherit"
      },
      headers: []
    };
    return e.id && (t.id = e.id), t;
  }
}), Sy = i.object({
  // v is a stringified number
  v: i.string().regex(/^\d+$/).transform(Number)
}), Jg = Et({
  latestVersion: 2,
  versionMap: {
    1: yn,
    2: Ay
  },
  getVersion(e) {
    const t = Sy.safeParse(e);
    return t.success ? t.data.v : yn.schema.safeParse(e).success ? 0 : null;
  }
}), hr = 2;
function ga(e) {
  return {
    v: hr,
    ...e
  };
}
function Oy(e) {
  if (e.v && e.v === hr)
    return e;
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(Oy), n = (e.requests ?? []).map(cy), a = e.auth ?? { authType: "inherit", authActive: !0 }, s = e.headers ?? [], o = ga({
    name: t,
    folders: r,
    requests: n,
    auth: a,
    headers: s
  });
  return e.id && (o.id = e.id), o;
}
function jy(e) {
  if (e.v && e.v === hr)
    return e;
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(jy), n = (e.requests ?? []).map($y), a = e.auth ?? { authType: "inherit", authActive: !0 }, s = e.headers ?? [], o = ga({
    name: t,
    folders: r,
    requests: n,
    auth: a,
    headers: s
  });
  return e.id && (o.id = e.id), o;
}
var te = function(e) {
  return function(t) {
    return !e(t);
  };
};
globalThis && globalThis.__spreadArray;
var Ey = os, ky = ss;
function ma(e, t) {
  return t === void 0 ? function(r) {
    return ma(e, r);
  } : ky(e, t) ? xn : An(t[e]);
}
var _a = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, Cy = function(e) {
  return function(t) {
    for (var r = [], n = 0; n < t.length; n++) {
      var a = e(n, t[n]);
      wn(a) && r.push(a.value);
    }
    return r;
  };
}, Iy = function(e) {
  return Cy(function(t, r) {
    return e(r);
  });
}, ba = function(e) {
  return e.slice();
}, Ry = as, gn = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, Ny = function(e) {
  return function(t) {
    return gn(gn([], t, !0), [e], !1);
  };
}, Py = Ny, Ly = function(e) {
  return [e];
}, My = Py, Zy = ma, Vy = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, Dy = function() {
  return {
    concat: function(e, t) {
      return e.concat(t);
    }
  };
}, Uy = function() {
  return {
    concat: Dy().concat,
    empty: []
  };
}, Ct = xn, qe = An;
function Ta(e) {
  return function(t) {
    return e(t) ? qe(t) : Ct;
  };
}
var Wt = function(e) {
  return function(t) {
    return $a(t) ? Ct : qe(e(t.value));
  };
}, qy = wn, $a = function(e) {
  return e._tag === "None";
}, By = function(e, t) {
  return function(r) {
    return $a(r) ? e() : t(r.value);
  };
}, Hy = By, vr = Hy, Gy = function(e, t) {
  for (var r = t(e); r._tag === "Left"; )
    r = t(r.left);
  return r.right;
}, re = Fa, B = Ka, zy = /* @__PURE__ */ qa(2, function(e, t) {
  return ye(e) ? e : t(e.right);
}), Be = function(e) {
  return function(t) {
    return ye(t) ? t : B(e(t.right));
  };
}, It = function(e) {
  return function(t) {
    return ye(t) ? re(e(t.left)) : t;
  };
}, ye = Ga, Fy = za, Ky = function(e) {
  return function(t) {
    return ye(t) ? e(t.left) : t.right;
  };
}, yr = Ky, mn = zy, yt = globalThis && globalThis.__assign || function() {
  return yt = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, yt.apply(this, arguments);
}, He = function(e, t, r) {
  return B({
    value: e,
    next: t,
    start: r
  });
}, Ge = function(e, t, r) {
  return t === void 0 && (t = []), r === void 0 && (r = !1), re({
    input: e,
    expected: t,
    fatal: r
  });
}, Wy = function(e, t) {
  return yt(yt({}, e), { expected: t });
}, Qy = function(e, t) {
  return Jy().concat(e, t);
}, Jy = function() {
  return {
    concat: function(e, t) {
      return e.input.cursor < t.input.cursor ? ts().concat(e, t) : e.input.cursor > t.input.cursor ? Pt().concat(e, t) : es({
        input: Pt(),
        fatal: Pt(),
        expected: Uy()
      }).concat(e, t);
    }
  };
}, Yy = function(e, t) {
  return t === void 0 && (t = 0), {
    buffer: e,
    cursor: t
  };
}, Xy = function(e) {
  return Zy(e.cursor, e.buffer);
}, eg = function(e) {
  return e.cursor >= e.buffer.length;
}, tg = function(e) {
  return g(Xy(e), Wt(function(t) {
    return { value: t, next: { buffer: e.buffer, cursor: e.cursor + 1 } };
  }));
}, X = globalThis && globalThis.__assign || function() {
  return X = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, X.apply(this, arguments);
}, gr = function(e) {
  return function(t) {
    return He(e, t, t);
  };
}, rg = function() {
  return function(e) {
    return Ge(e);
  };
}, ng = function(e) {
  return function() {
    return Ge(e);
  };
}, E = function(e) {
  return g(ig(ag()), le(function(t) {
    var r = t[0], n = t[1];
    return e(r) ? ge(r) : ng(n);
  }));
}, j = function(e, t) {
  return function(r) {
    return g(e(r), It(function(n) {
      return Wy(n, [t]);
    }));
  };
}, ag = function() {
  return function(e) {
    return g(tg(e), vr(function() {
      return Ge(e);
    }, function(t) {
      var r = t.value, n = t.next;
      return He(r, n, e);
    }));
  };
}, sg = function(e, t) {
  return function(r) {
    return g(e(r), mn(function(n) {
      return g(t(n.value)(n.next), mn(function(a) {
        return He(a.value, a.next, r);
      }));
    }));
  };
}, Rt = function(e, t) {
  return function(r) {
    var n = e(r);
    return Fy(n) || n.left.fatal ? n : g(t()(r), It(function(a) {
      return Qy(n.left, a);
    }));
  };
}, ig = function(e) {
  return function(t) {
    return g(e(t), Be(function(r) {
      return X(X({}, r), { value: [r.value, t] });
    }));
  };
}, wa = function(e) {
  return de(function() {
    return ge(e.empty);
  });
}, mr = function() {
  return j(function(e) {
    return eg(e) ? He(void 0, e, e) : Ge(e);
  }, "end of file");
}, og = function(e) {
  return g(_r(e), de(function() {
    return ge([]);
  }));
}, _r = function(e) {
  return g(e, le(function(t) {
    return Tr(Ly(t), function(r) {
      return g(e, O(function(n) {
        return re(My(n)(r));
      }), de(function() {
        return ge(B(r));
      }));
    });
  }));
}, cg = function(e, t) {
  return function(r) {
    return g(e, le(function() {
      return r;
    }), $r(function() {
      return t;
    }));
  };
}, xa = function(e) {
  return cg(e, e);
}, ug = function(e) {
  return og(E(te(e)));
}, br = function(e, t) {
  return g(t, O(function() {
    return Ry;
  }), de(function() {
    return lg(e, t);
  }));
}, lg = function(e, t) {
  return g(e, le(function(r) {
    return Tr(cs(r), function(n) {
      return g(t, O(function() {
        return B(n);
      }), de(function() {
        return g(e, O(function(a) {
          return re(Ey(a)(n));
        }));
      }));
    });
  }));
}, ze = function(e, t) {
  return function(r) {
    return g(e(r), Be(function(n) {
      return X(X({}, n), { value: t(n.value) });
    }));
  };
}, Aa = function(e, t) {
  return Nt(e, function(r) {
    return ze(t, r);
  });
}, Nt = function(e, t) {
  return sg(e, t);
}, Tr = function(e, t) {
  var r = function(n) {
    return function(a) {
      return ye(a.value) ? re({ value: a.value.left, stream: a.next }) : B(He(a.value.right, a.next, n));
    };
  };
  return function(n) {
    return Gy({ value: e, stream: n }, function(a) {
      var s = t(a.value)(a.stream);
      return ye(s) ? B(Ge(a.stream, s.left.expected, s.left.fatal)) : r(n)(s.right);
    });
  };
}, dg = function(e, t) {
  return Rt(e, t);
}, O = function(e) {
  return function(t) {
    return ze(t, e);
  };
}, ge = gr, le = function(e) {
  return function(t) {
    return Nt(t, e);
  };
}, $r = function(e) {
  return function(t) {
    return Nt(t, function(r) {
      return ze(e(r), function() {
        return r;
      });
    });
  };
}, de = function(e) {
  return function(t) {
    return dg(t, e);
  };
}, fg = "Parser", pg = function(e) {
  return {
    concat: function(t, r) {
      return Aa(ze(t, function(n) {
        return function(a) {
          return e.concat(n, a);
        };
      }), r);
    }
  };
}, hg = function(e) {
  return X(X({}, pg(e)), { empty: gr(e.empty) });
}, vg = {
  URI: fg,
  map: ze,
  ap: Aa,
  chain: Nt,
  chainRec: Tr
}, Sa = function(e, t, r) {
  var n;
  return Object.assign({}, e, (n = {}, n[t] = r, n));
}, Oa = function(e) {
  return function(t) {
    return g(t, O(function(r) {
      return Sa({}, e, r);
    }));
  };
}, Qt = function(e, t) {
  return function(r) {
    return g(r, le(function(n) {
      return g(t(n), O(function(a) {
        return Sa(n, e, a);
      }));
    }));
  };
}, yg = function(e) {
  return rs(e)(e.empty);
};
ns.concat;
var gg = wa(Xt), me = function(e) {
  return j(E(function(t) {
    return t === e;
  }), '"'.concat(e, '"'));
}, mg = function(e) {
  return j(E(function(t) {
    return t !== e;
  }), 'anything but "'.concat(e, '"'));
}, wr = function(e) {
  return gg(Fe(e));
}, Fe = function(e) {
  return g(_r(e), O(function(t) {
    return t.join("");
  }));
}, xr = function(e) {
  return "0123456789".indexOf(e) !== -1;
}, Jt = j(E(xr), "a digit"), _g = /^\s$/, ja = function(e) {
  return _g.test(e);
}, Ea = j(E(ja), "a whitespace"), bg = function(e) {
  return e === "_";
}, Ke = function(e) {
  return /[a-z]/.test(e.toLowerCase());
}, ka = function(e) {
  return Ke(e) || xr(e) || bg(e);
};
j(E(ka), "a word character");
j(E(Ke), "a letter");
var Tg = function(e) {
  return e.toLowerCase() !== e.toUpperCase();
};
j(E(Tg), "an unicode letter");
var Ca = function(e) {
  return Ke(e) && e === e.toUpperCase();
};
j(E(Ca), "an upper case letter");
var Ia = function(e) {
  return Ke(e) && e === e.toLowerCase();
};
j(E(Ia), "a lower case letter");
j(E(te(xr)), "a non-digit");
var Ra = j(E(te(ja)), "a non-whitespace character");
j(E(te(ka)), "a non-word character");
j(E(te(Ke)), "a non-letter character");
j(E(te(Ca)), "anything but an upper case letter");
j(E(te(Ia)), "anything but a lower case letter");
var Na = function(e) {
  return j(vg.chainRec(e, function(t) {
    return g(xg(0, t), vr(function() {
      return ge(B(e));
    }, function(r) {
      return g(me(r), le(function() {
        return ge(re(t.slice(1)));
      }));
    }));
  }), JSON.stringify(e));
}, Yt = yg(hg(Xt)), Ve = wa(Xt), $g = function(e) {
  return Ve(wg(e));
}, wg = function(e) {
  return g(_r(e), O(function(t) {
    return t.join("");
  }));
}, xg = function(e, t) {
  return e >= 0 && e < t.length ? qe(t.charAt(e)) : Ct;
}, Ag = wr(Ea);
Fe(Ea);
wr(Ra);
Fe(Ra);
var Sg = function(e) {
  var t = +e;
  return isNaN(t) || e === "" ? Ct : qe(t);
};
j(g(Yt([Ve(me("-")), Fe(Jt)]), O(function(e) {
  return +e;
})), "an integer");
j(g(Yt([Ve(me("-")), wr(Jt), Ve(Yt([me("."), Fe(Jt)]))]), le(function(e) {
  return g(Sg(e), vr(function() {
    return rg();
  }, gr));
})), "a float");
var Og = xa(me('"'))($g(Rt(Na('\\"'), function() {
  return mg('"');
})));
function Pa(e) {
  return function(t) {
    return t(Yy(e.split("")));
  };
}
const _n = (e, t) => (r) => ({
  ...r,
  [e]: t(r[e])
}), jg = ["#", ":"], _e = xa(Ag), Ar = (e) => (t) => t.join(e), Sr = (e) => g(
  ug((t) => e.includes(t)),
  O(Ar(""))
), Eg = W(
  Sr,
  $r(() => E(() => !0))
), La = g(
  Og,
  O((e) => JSON.parse(`"${e}"`))
), kg = g(
  _e(La),
  de(
    () => g(
      Sr([":", `
`]),
      O(Sn)
    )
  )
), Cg = g(
  _e(La),
  de(
    () => g(
      Sr([`
`]),
      O(Sn)
    )
  )
), Ma = g(
  Ve(Na("#")),
  O(te(On))
), Za = g(
  _e(Ma),
  Oa("commented"),
  Qt("key", () => _e(kg)),
  $r(() => me(":")),
  Qt("value", () => Cg)
), Ig = g(
  _e(Ma),
  Oa("commented"),
  Qt("key", () => Rt(
    Eg([`
`]),
    () => g(
      br(E((e) => !0), mr()),
      O(W(
        ba,
        Ar("")
      ))
    )
  )),
  O(W(
    Ta(({ key: e }) => !On(e))
  ))
), Rg = g(
  br(_e(Za), mr())
), Ng = g(
  br(
    Rt(
      g(Za, O(qe)),
      () => g(
        Ig,
        O(W(
          Wt((e) => ({ ...e, value: "" }))
        ))
      )
    ),
    mr()
  ),
  O(W(
    Iy(W(
      Ta(qy),
      Wt((e) => e.value)
    ))
  ))
), Pg = (e) => {
  if (jg.some((n) => e.includes(n)))
    return !0;
  const t = JSON.stringify(e);
  return t.substring(1, t.length - 1).trim() !== e;
}, bn = (e) => Pg(e) ? JSON.stringify(e) : e, Yg = (e) => g(
  e,
  Vy(
    W(
      _n("key", bn),
      _n("value", bn),
      ({ key: t, value: r, active: n }) => n ? `${t}: ${r}` : `# ${t}: ${r}`
    )
  ),
  Ar(`
`)
), Lg = (e) => g(
  Ng,
  Pa(e),
  It((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  Be(
    ({ value: t }) => g(
      t,
      _a(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), Xg = (e) => g(
  Rg,
  Pa(e),
  It((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  Be(
    ({ value: t }) => g(
      t,
      _a(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), em = W(
  Lg,
  Be(ba),
  yr(() => [])
), Mg = i.object({
  id: i.optional(i.string()),
  name: i.string(),
  variables: i.array(
    i.object({
      key: i.string(),
      value: i.string()
    })
  )
}), Tn = ee({
  initial: !0,
  schema: Mg
}), Zg = i.object({
  v: i.literal(1),
  id: i.string(),
  name: i.string(),
  variables: i.array(
    i.union([
      i.object({
        key: i.string(),
        secret: i.literal(!0)
      }),
      i.object({
        key: i.string(),
        value: i.string(),
        secret: i.literal(!1)
      })
    ])
  )
}), Vg = ee({
  initial: !1,
  schema: Zg,
  up(e) {
    return {
      ...e,
      v: 1,
      id: e.id ?? "",
      variables: e.variables.map((r) => ({
        ...r,
        secret: !1
      }))
    };
  }
}), Dg = i.object({
  v: i.number()
}), tm = Et({
  latestVersion: 1,
  versionMap: {
    0: Tn,
    1: Vg
  },
  getVersion(e) {
    const t = Dg.safeParse(e);
    return t.success ? t.data.v : Tn.schema.safeParse(e).success ? 0 : null;
  }
}), gt = /<<([^>]*)>>/g, mt = 10, Va = "ENV_EXPAND_LOOP", $n = 1;
function Ug(e, t) {
  let r = e, n = 0;
  for (; r.match(gt) != null && n <= mt; )
    r = r.replace(gt, (a) => {
      const s = t.find(
        (o) => o.key === a.replace(/[<>]/g, "")
      );
      return s && "value" in s ? s.value : a;
    }), n++;
  return n > mt ? re(Va) : B(r);
}
const rm = (e, t) => g(
  Ug(e, t),
  yr(() => e)
);
function qg(e, t, r = !1) {
  if (!t || !e)
    return B(e);
  let n = e, a = 0;
  for (; n.match(gt) != null && a <= mt; )
    n = decodeURI(encodeURI(n)).replace(gt, (s, o) => {
      const c = t.find((l) => l && l.key === o);
      return c && "value" in c ? c.secret && r ? "*".repeat(
        c.value.length
      ) : c.value : "";
    }), a++;
  return a > mt ? re(Va) : B(n);
}
const nm = (e, t, r = !1) => g(
  qg(e, t, r),
  yr(() => e)
), Bg = (e) => ({
  key: e.key,
  value: e.value,
  secret: !1
}), am = (e) => {
  if (e.v && e.v === $n)
    return e;
  const t = e.id ?? "", r = e.name ?? "Untitled", n = (e.variables ?? []).map(Bg);
  return {
    v: $n,
    id: t,
    name: r,
    variables: n
  };
};
export {
  hr as CollectionSchemaVersion,
  tm as Environment,
  $n as EnvironmentSchemaVersion,
  ca as FormDataKeyValue,
  fr as GQLHeader,
  ha as GQL_REQ_SCHEMA_VERSION,
  Jg as HoppCollection,
  pa as HoppGQLAuth,
  vy as HoppGQLAuthAPIKey,
  fy as HoppGQLAuthBasic,
  py as HoppGQLAuthBearer,
  yy as HoppGQLAuthInherit,
  dy as HoppGQLAuthNone,
  hy as HoppGQLAuthOAuth2,
  pr as HoppGQLRequest,
  lr as HoppRESTAuth,
  Bh as HoppRESTAuthAPIKey,
  Dh as HoppRESTAuthBasic,
  Uh as HoppRESTAuthBearer,
  Hh as HoppRESTAuthInherit,
  Vh as HoppRESTAuthNone,
  qh as HoppRESTAuthOAuth2,
  dr as HoppRESTHeaders,
  ua as HoppRESTReqBody,
  Hg as HoppRESTReqBodyFormData,
  kt as HoppRESTRequest,
  iy as RESTReqSchemaVersion,
  Gg as ValidContentTypesList,
  Ty as getDefaultGQLRequest,
  oy as getDefaultRESTRequest,
  zg as isEqualHoppRESTRequest,
  Wg as isHoppRESTRequest,
  ny as knownContentTypes,
  ga as makeCollection,
  Qg as makeGQLRequest,
  Kg as makeRESTRequest,
  rm as parseBodyEnvVariables,
  Ug as parseBodyEnvVariablesE,
  em as parseRawKeyValueEntries,
  Lg as parseRawKeyValueEntriesE,
  nm as parseTemplateString,
  qg as parseTemplateStringE,
  Yg as rawKeyValueEntriesToString,
  Fg as safelyExtractRESTRequest,
  Xg as strictParseRawKeyValueEntriesE,
  $y as translateToGQLRequest,
  am as translateToNewEnvironment,
  Bg as translateToNewEnvironmentVariables,
  jy as translateToNewGQLCollection,
  Oy as translateToNewRESTCollection,
  cy as translateToNewRequest
};
